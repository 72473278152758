import {writable, type Writable} from 'svelte/store';
import {CourseLightModel, ReviewModel} from "@24hbs/utils";
import type { HomePageSettingsData } from './types';
import type { INews, IPromoBoxes } from '$lib/utils/contentful/types';

export class HomePageStore {
  private static homePageStore: HomePageStore|null;
  
  public trendCourses: Writable<CourseLightModel[]> = <Writable<CourseLightModel[]>>writable([]);
  public popularCourses: Writable<CourseLightModel[]> = <Writable<CourseLightModel[]>>writable([]);
  
  public aboutUsReviews: Writable<ReviewModel[]> = <Writable<ReviewModel[]>>writable([]);
  public promoBox: Writable<IPromoBoxes> = <Writable<IPromoBoxes>><unknown>writable([]);
  public news: Writable<INews[]> = <Writable<INews[]>>writable([]);
  
  public static getInstance(): HomePageStore{
    if (HomePageStore.homePageStore == null) {
      HomePageStore.homePageStore = new HomePageStore();
    }
    return HomePageStore.homePageStore;
  }
  
  private constructor() {
    //console.log("Building homepage store...");
  }
  
  public loadPreloadedHomePage(hpSettings: HomePageSettingsData){
    this.trendCourses.update( current => {
      current = hpSettings.trendCourses
      return current;
    });
    
    this.popularCourses.update( current => {
      current = hpSettings.popularCourses;
      return current;
    });
    
    this.aboutUsReviews.update( current => {
      current = hpSettings.aboutUsReviews;
      return current;
    });
    
    this.promoBox.update( current => {
      current = hpSettings.promoBox;
      return current;
    });
    this.news.update( current => {
      current = hpSettings.news;
      return current;
    });
  }
  
  
  public static initHomePageSettingsData(): HomePageSettingsData{
    return <HomePageSettingsData>{
      trendCourses: [],
      popularCourses: [],
      aboutUsReviews: [],
      promoBox: null,
      news: [],
    };
  }
  
  public static fromJSON (homePageDataObj: HomePageSettingsData): HomePageSettingsData {
    const homePageData = {
      trendCourses: [],
      popularCourses: [],
      aboutUsReviews: [],
      promoBox: null,
      news: [],
    }
    for (let i = 0; i < homePageDataObj.trendCourses.length; i++) {
      const course = CourseLightModel.fromJSON(homePageDataObj.trendCourses[i]);
      homePageData.trendCourses.push(course);
    }
    for (let i = 0; i < homePageDataObj.popularCourses.length; i++) {
      const course = CourseLightModel.fromJSON(homePageDataObj.popularCourses[i]);
      homePageData.popularCourses.push(course);
    }
    for (let i = 0; i < homePageDataObj.aboutUsReviews.length; i++) {
      const review = ReviewModel.fromJSON(homePageDataObj.aboutUsReviews[i]);
      homePageData.aboutUsReviews.push(review);
    }
    if (homePageDataObj.promoBox) {
      homePageData.promoBox = homePageDataObj.promoBox;
    }
    if (homePageDataObj.news) {
      homePageData.news = homePageDataObj.news;
    }
    
    return homePageData;
  }
  
  
}





