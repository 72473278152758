import { writable, type Writable } from 'svelte/store';
import { CourseLightModel, stringToSlug } from "@24hbs/utils";
import { SettingsUtils } from "$lib/utils/settings-utils";
import type { TopCoursesByCourseCategory, TopicsListCourses } from './types';

export enum CourseCategory {
  MarketingComunicazioneDigitalSocial,
  GestioneImpresaExport,
  HRLavoroSviluppoManageriale,
  AmministrazioneFinanzaControllo,
  LussoModa,
  VenditeRetail,
  AmbienteEnergiaPA,
  SanitaPharmaBiomed,
  ArteCinemaDesign,
  DirittoFisco,
  FoodBeverageTurismo,
  Sport,
  CodingAcademy,
  DigitalAcademy
};

const courseCategoryMap = [
  CourseCategory.MarketingComunicazioneDigitalSocial,
  CourseCategory.GestioneImpresaExport,
  CourseCategory.HRLavoroSviluppoManageriale,
  CourseCategory.AmministrazioneFinanzaControllo,
  CourseCategory.LussoModa,
  CourseCategory.VenditeRetail,
  CourseCategory.AmbienteEnergiaPA,
  CourseCategory.SanitaPharmaBiomed,
  CourseCategory.ArteCinemaDesign,
  CourseCategory.DirittoFisco,
  CourseCategory.FoodBeverageTurismo,
  CourseCategory.Sport,
  CourseCategory.CodingAcademy,
  CourseCategory.DigitalAcademy
];

export class CourseCategoryStore {
  private static settingStore: CourseCategoryStore|null;
  
  public bestSellerCourses: Writable<TopicsListCourses> = <Writable<TopicsListCourses>>writable(CourseCategoryStore.initTopicsListCourses());
  
  public static getInstance(): CourseCategoryStore{
    
    if(CourseCategoryStore.settingStore==null){
      CourseCategoryStore.settingStore = new CourseCategoryStore();
    }
    return CourseCategoryStore.settingStore;
  }
  
  private constructor() {
    //console.log("Building course category store...");
  }
  
  public loadPreloadedCourseCategories(){
    
    const topCoursesByCourseCategory = SettingsUtils.getTopCoursesByCourseCategory();
    
    this.bestSellerCourses.update( current => {
      current = topCoursesByCourseCategory.bestSellerCourses;
      return current;
    });
  }
  
  public static initTopCoursesByCourseCategory(): TopCoursesByCourseCategory {
    
    return <TopCoursesByCourseCategory>{
      bestSellerCourses: CourseCategoryStore.initTopicsListCourses()
    };
  }
  
  public static fromJSON (topCoursesObj: TopCoursesByCourseCategory): TopCoursesByCourseCategory {
    const topCoursesByCourseCategory = {
      bestSellerCourses: CourseCategoryStore.initTopicsListCourses()
    }
    
    for (let i = 0; i < topCoursesObj.bestSellerCourses.topics.length; i++) {
      if(topCoursesObj.bestSellerCourses?.topics[i]) {
        for (let k = 0; k < topCoursesObj.bestSellerCourses.topics[i].courses.length; k++) {
          const course = CourseLightModel.fromJSON(topCoursesObj.bestSellerCourses.topics[i].courses[k]);
          topCoursesByCourseCategory.bestSellerCourses.topics[i].courses.push(course);
        }
      }
    }
    
    return topCoursesByCourseCategory;
  }
  
  private static initTopicsListCourses (): TopicsListCourses {
    const topicsListCourses: TopicsListCourses = {
      topics: []
    };
    for (const categoryKey of courseCategoryMap) {
      topicsListCourses.topics[categoryKey] = {
        courses: []
      }
    }
    
    return topicsListCourses;
  };
  
  public getTopCoursesByCourseCategoryEmptyObject(): TopCoursesByCourseCategory {
    return <TopCoursesByCourseCategory>{
      bestSellerCourses: CourseCategoryStore.initTopicsListCourses(),
    };
  }
  
  public static isValidCategory (courseCategory: CourseCategory): boolean {
    if (courseCategory >= 0 && courseCategory <= 11) {
      return true;
    } else {
      return false;
    }
  }
  
  public static getCourseCategoryByFieldName (remoteSettingsFieldName: string): number {
    switch (remoteSettingsFieldName) {
      case "temBestSellerMarketing":
      return CourseCategory.MarketingComunicazioneDigitalSocial;
      
      case "temBestSellerGestione":
      return CourseCategory.GestioneImpresaExport;
      
      case "temBestSellerHR":
      return CourseCategory.HRLavoroSviluppoManageriale;
      
      case "temBestSellerAmministrazione":
      return CourseCategory.AmministrazioneFinanzaControllo;
      
      case "temBestSellerLusso":
      return CourseCategory.LussoModa;
      
      case "temBestSellerVendite":
      return CourseCategory.VenditeRetail;
      
      case "temBestSellerAmbiente":
      return CourseCategory.AmbienteEnergiaPA;
      
      case "temBestSellerSanita":
      return CourseCategory.SanitaPharmaBiomed;
      
      case "temBestSellerArte":
      return CourseCategory.ArteCinemaDesign;
      
      case "temBestSellerDiritto":
      return CourseCategory.DirittoFisco;
      
      case "temBestSellerFood":
      return CourseCategory.FoodBeverageTurismo;
      
      case "temBestSellerSport":
      return CourseCategory.Sport;
      
      case "temBestSellerCodingAcademy":
      return CourseCategory.CodingAcademy;
      
      case "temBestSellerDigitalAcademy":
      return CourseCategory.DigitalAcademy;
      
      default:
      return -1;
      break;
    }
  }
  
  public static getCourseCategoryNameByCategorySlug (courseCategorySlug: string): string {
    switch (courseCategorySlug) {
      case stringToSlug("Marketing, Comunicazione, Digital e Social Media"):
      return "Marketing, Comunicazione, Digital e Social Media";
      break;
      
      case stringToSlug("Gestione di Impresa, Export e Internazionalizzazione"):
      return "Gestione di Impresa, Export e Internazionalizzazione";
      break;
      
      case stringToSlug("HR, Lavoro e Sviluppo Manageriale"):
      return "HR, Lavoro e Sviluppo Manageriale";
      break;
      
      case stringToSlug("Amministrazione, finanza e controllo"):
      return "Amministrazione, finanza e controllo";
      break;
      
      case stringToSlug("Lusso e Moda"):
      return "Lusso e Moda";
      break;
      
      case stringToSlug("Vendite e Retail"):
      return "Vendite e Retail";
      break;
      
      case stringToSlug("Ambiente, Energia e P.A."):
      case 'ambiente-energia-e-p-a':
      case 'ambiente-energia-pa':
      return "Ambiente, Energia e P.A.";
      break;
      case 'self-education-sanita':
      case stringToSlug("Sanità, Pharma e Biomed"):
      return "Sanità, Pharma e Biomed";
      break;
      
      case stringToSlug("Arte, Cinema e Design"):
      return "Arte, Cinema e Design";
      break;
      
      case stringToSlug("Diritto e Fisco"):
      return "Diritto e Fisco";
      break;
      
      case stringToSlug("Food, Beverage e Turismo"):
      return "Food, Beverage e Turismo";
      break;
      
      case stringToSlug("Sport"):
      return "Sport";
      break;
      
      case stringToSlug("Coding Academy"):
      return "Coding Academy";
      break;
      
      case stringToSlug("Digital Academy"):
      return "Digital Academy";
      break;
      
      default:
      return "";
      break;
    }
  }
  
  public static getCourseCategoryNameByCategory (courseCategory: CourseCategory): string {
    switch (courseCategory) {
      case CourseCategory.MarketingComunicazioneDigitalSocial:
      return "Marketing, Comunicazione, Digital e Social Media";
      
      case CourseCategory.GestioneImpresaExport:
      return "Gestione di Impresa, Export e Internazionalizzazione";
      
      case CourseCategory.HRLavoroSviluppoManageriale:
      return "HR, Lavoro e Sviluppo Manageriale";
      
      case CourseCategory.AmministrazioneFinanzaControllo:
      return "Amministrazione, finanza e controllo";
      
      case CourseCategory.LussoModa:
      return "Lusso e Moda";
      
      case CourseCategory.VenditeRetail:
      return "Vendite e Retail";
      
      case CourseCategory.AmbienteEnergiaPA:
      return "Ambiente, Energia e P.A.";
      
      case CourseCategory.SanitaPharmaBiomed:
      return "Sanità, Pharma e Biomed";
      
      case CourseCategory.ArteCinemaDesign:
      return "Arte, Cinema e Design";
      
      case CourseCategory.DirittoFisco:
      return "Diritto e Fisco";
      
      case CourseCategory.FoodBeverageTurismo:
      return "Food, Beverage e Turismo";
      
      case CourseCategory.Sport:
      return "Sport";
      
      case CourseCategory.CodingAcademy:
      return "Coding Academy";
      
      case CourseCategory.DigitalAcademy:
      return "Digital Academy";
      
      default:
      return "";
    }
  }
  
  public static getCourseCategoryByCategoryName (courseCategoryName: string): number {
    switch (courseCategoryName.toLowerCase()) {
      case "marketing, comunicazione, digital e social media":
      case "marketing comunicazione digital e social media":
      return CourseCategory.MarketingComunicazioneDigitalSocial;
      
      case "gestione di impresa, export e internazionalizzazione":
      case "gestione di impresa export e internazionalizzazione":
      return CourseCategory.GestioneImpresaExport;
      
      case "hr, lavoro e sviluppo manageriale":
      case "hr lavoro e sviluppo manageriale":
      return CourseCategory.HRLavoroSviluppoManageriale;
      
      case "amministrazione, finanza e controllo":
      case "amministrazione finanza e controllo":
      return CourseCategory.AmministrazioneFinanzaControllo;
      
      case "lusso e moda":
      return CourseCategory.LussoModa;
      
      case "vendite e retail":
      return CourseCategory.VenditeRetail;
      
      case "ambiente, energia e p.a.":
      case "ambiente, energia e pa":
      case "ambiente, energia e p a":
      case "ambiente energia e p.a.":
      case "ambiente energia e pa":
      case "ambiente energia e p a":
      return CourseCategory.AmbienteEnergiaPA;
      
      case "self education sanita":
      case "self education sanità":
      case "sanita, pharma e biomed":
      case "sanita pharma e biomed":
      case "sanità, pharma e biomed":
      case "sanità pharma e biomed":
      return CourseCategory.SanitaPharmaBiomed;
      
      case "arte, cinema e design":
      case "arte cinema e design":
      return CourseCategory.ArteCinemaDesign;
      
      case "diritto e fisco":
      return CourseCategory.DirittoFisco;
      
      case "food, beverage e turismo":
      case "food beverage e turismo":
      return CourseCategory.FoodBeverageTurismo;
      
      case "sport":
      return CourseCategory.Sport;
      
      case "coding academy":
      return CourseCategory.CodingAcademy;
      
      case "digital academy":
      return CourseCategory.DigitalAcademy;
      
      default:
      return -1;
    }
  }
  
  public static isValidCategorySlug (courseCategorySlug: string): boolean {
    return CourseCategoryStore.getCourseCategoryByCategorySlug(courseCategorySlug)!==-1;
  }
  
  public static floorCategory (courseCategorySlug: string): string {
    const courseCategory = CourseCategoryStore.getCourseCategoryByCategorySlug(courseCategorySlug);
    switch (courseCategory) {
      
      case CourseCategory.MarketingComunicazioneDigitalSocial:
      return stringToSlug("Marketing, Comunicazione, Digital e Social Media");
      break;
      
      case CourseCategory.GestioneImpresaExport:
      return stringToSlug("Gestione di Impresa, Export e Internazionalizzazione");
      break;
      
      case CourseCategory.HRLavoroSviluppoManageriale:
      return stringToSlug("HR, Lavoro e Sviluppo Manageriale");
      break;
      
      case CourseCategory.AmministrazioneFinanzaControllo:
      return stringToSlug("Amministrazione, finanza e controllo");
      break;
      
      case CourseCategory.LussoModa:
      return stringToSlug("Lusso e Moda");
      break;
      
      case CourseCategory.VenditeRetail:
      return stringToSlug("Vendite e Retail");
      break;
      case CourseCategory.AmbienteEnergiaPA:
      return stringToSlug("Ambiente, Energia e P.A.");
      break;
      
      case CourseCategory.SanitaPharmaBiomed:
      return stringToSlug("Sanità, Pharma e Biomed");
      break;
      
      case CourseCategory.ArteCinemaDesign:
      return stringToSlug("Arte, Cinema e Design");
      break;
      
      case CourseCategory.DirittoFisco:
      return stringToSlug("Diritto e Fisco");
      break;
      
      case CourseCategory.FoodBeverageTurismo:
      return stringToSlug("Food, Beverage e Turismo");
      break;
      
      case CourseCategory.Sport:
      return stringToSlug("Sport");
      break;
      
      case CourseCategory.CodingAcademy:
      return stringToSlug("Coding Academy");
      break;
      
      case CourseCategory.DigitalAcademy:
      return stringToSlug("Digital Academy");
      break;
      
      default:
      return "";
      break;
    }
  }
  
  public static getCourseCategorySlugByCategory (courseCategory: CourseCategory): string {
    switch (courseCategory) {
      case CourseCategory.MarketingComunicazioneDigitalSocial:
      return stringToSlug("Marketing, Comunicazione, Digital e Social Media");
      
      case CourseCategory.GestioneImpresaExport:
      return stringToSlug("Gestione di Impresa, Export e Internazionalizzazione");
      
      case CourseCategory.HRLavoroSviluppoManageriale:
      return stringToSlug("HR, Lavoro e Sviluppo Manageriale");
      
      case CourseCategory.AmministrazioneFinanzaControllo:
      return stringToSlug("Amministrazione, finanza e controllo");
      
      case CourseCategory.LussoModa:
      return stringToSlug("Lusso e Moda");
      
      case CourseCategory.VenditeRetail:
      return stringToSlug("Vendite e Retail");
      case CourseCategory.AmbienteEnergiaPA:
      return stringToSlug("Ambiente, Energia e P.A.");
      
      case CourseCategory.SanitaPharmaBiomed:
      return stringToSlug("Sanità, Pharma e Biomed");
      
      case CourseCategory.ArteCinemaDesign:
      return stringToSlug("Arte, Cinema e Design");
      
      case CourseCategory.DirittoFisco:
      return stringToSlug("Diritto e Fisco");
      
      case CourseCategory.FoodBeverageTurismo:
      return stringToSlug("Food, Beverage e Turismo");
      
      case CourseCategory.Sport:
      return stringToSlug("Sport");
      
      case CourseCategory.CodingAcademy:
      return stringToSlug("Coding Academy");
      
      case CourseCategory.DigitalAcademy:
      return stringToSlug("Digital Academy");
      
      default:
      return "";
    }
  }
  
  public static getCourseCategoryByCategorySlug (courseCategorySlug: string): number {
    switch (courseCategorySlug) {
      
      case stringToSlug("Marketing, Comunicazione, Digital e Social Media"):
      return CourseCategory.MarketingComunicazioneDigitalSocial;
      break;
      case 'gestione-dimpresa-export-e-internazionalizzazione':
      case stringToSlug("Gestione di Impresa, Export e Internazionalizzazione"):
      return CourseCategory.GestioneImpresaExport;
      break;
      
      case stringToSlug("HR, Lavoro e Sviluppo Manageriale"):
      return CourseCategory.HRLavoroSviluppoManageriale;
      break;
      
      case stringToSlug("Amministrazione, finanza e controllo"):
      return CourseCategory.AmministrazioneFinanzaControllo;
      break;
      
      case stringToSlug("Lusso e Moda"):
      return CourseCategory.LussoModa;
      break;
      
      case stringToSlug("Vendite e Retail"):
      return CourseCategory.VenditeRetail;
      break;
      case stringToSlug("Ambiente, Energia e P.A."):
      case 'ambiente-energia-e-p-a':
      case 'ambiente-energia-pa':
      return CourseCategory.AmbienteEnergiaPA;
      break;
      
      case 'self-education-sanita':
      case stringToSlug("Sanità, Pharma e Biomed"):
      return CourseCategory.SanitaPharmaBiomed;
      break;
      
      case stringToSlug("Arte, Cinema e Design"):
      return CourseCategory.ArteCinemaDesign;
      break;
      
      case stringToSlug("Diritto e Fisco"):
      return CourseCategory.DirittoFisco;
      break;
      
      case stringToSlug("Food, Beverage e Turismo"):
      return CourseCategory.FoodBeverageTurismo;
      break;
      
      case stringToSlug("Sport"):
      return CourseCategory.Sport;
      break;
      
      case stringToSlug("Coding Academy"):
      return CourseCategory.CodingAcademy;
      break;
      
      case stringToSlug("Digital Academy"):
      return CourseCategory.DigitalAcademy;
      break;
      
      default:
      return -1;
      break;
    }
  }
  
  public static getCourseCategories (): string[] {
    return [
      "Marketing, Comunicazione, Digital e Social Media",
      "Gestione di Impresa, Export e Internazionalizzazione",
      "HR, Lavoro e Sviluppo Manageriale",
      "Amministrazione, finanza e controllo",
      "Lusso e Moda",
      "Vendite e Retail",
      "Ambiente, Energia e P.A.",
      "Sanità, Pharma e Biomed",
      "Arte, Cinema e Design",
      "Diritto e Fisco",
      "Food, Beverage e Turismo",
      "Sport",
      "Coding Academy",
      "Digital Academy"
    ]
  };
}