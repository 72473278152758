import { writable, type Writable } from 'svelte/store';
import { CourseLightModel, stringToSlug } from "@24hbs/utils";
import type { CourseTypesListCourses, TopCoursesByCourseType } from './types';

export enum CourseType {
  CorsiExecutive,
  CorsiLaurea,
  CorsiOnline,
  ExecutiveMaster,
  ExecutiveMBA,
  MasterPostLaureaConStage,
  Masterclass,
  MBAFullTime,
  MBAPartTime,
  ProfessionalMaster,
  StudyTour,
  MasterOnline,
}

const courseTypeMap = [
  CourseType.CorsiExecutive,
  CourseType.CorsiLaurea,
  CourseType.CorsiOnline,
  CourseType.ExecutiveMaster,
  CourseType.ExecutiveMBA,
  CourseType.MasterPostLaureaConStage,
  CourseType.Masterclass,
  CourseType.MBAFullTime,
  CourseType.MBAPartTime,
  CourseType.ProfessionalMaster,
  CourseType.StudyTour,
  CourseType.MasterOnline,
];

export class CourseTypeStore {
  private static courseTypesStore: CourseTypeStore|null;
  
  public courseTypesBestSellerCourses: Writable<CourseTypesListCourses> = <Writable<CourseTypesListCourses>>writable(CourseTypeStore.initCourseTypesListCourses());
  
  public static getInstance(): CourseTypeStore{
    
    if (CourseTypeStore.courseTypesStore == null) {
      CourseTypeStore.courseTypesStore = new CourseTypeStore();
    }
    return CourseTypeStore.courseTypesStore;
  }
  
  private constructor() {
    //console.log("Building course types store...");
  }
  
  public loadPreloadedCourseTypes(topCoursesByCourseType: TopCoursesByCourseType){
    
    this.courseTypesBestSellerCourses.update( current => {
      current = topCoursesByCourseType.bestSellerCourses
      return current;
    });
  }
  
  
  public static initTopCoursesByCourseType(): TopCoursesByCourseType{
    return <TopCoursesByCourseType>{
      bestSellerCourses: CourseTypeStore.initCourseTypesListCourses()
    };
  }
  
  public static fromJSON (topCoursesObj: TopCoursesByCourseType): TopCoursesByCourseType {
    //console.log("Converting from JSON TopCoursesByCourseType: ",topCoursesObj);
    const topCoursesByCourseType = {
      bestSellerCourses: CourseTypeStore.initCourseTypesListCourses()
    }
    for (let i = 0; i < topCoursesObj.bestSellerCourses.types.length; i++) {
      // console.log("Checking bestSellerCourses for ",i," :",topCoursesObj.bestSellerCourses.types[i].courses);
      if(topCoursesObj.bestSellerCourses?.types[i]) {
        for (let k = 0; k < topCoursesObj.bestSellerCourses.types[i].courses.length; k++) {
          const course = CourseLightModel.fromJSON(topCoursesObj.bestSellerCourses.types[i].courses[k]);
          topCoursesByCourseType.bestSellerCourses.types[i].courses.push(course);
        }
      }
    }
    
    return topCoursesByCourseType;
  }
  
  private static initCourseTypesListCourses (): CourseTypesListCourses {
    const courseTypesListCourses: CourseTypesListCourses = {
      types: []
    };
    for (const courseTypeKey of courseTypeMap) {
      courseTypesListCourses.types[courseTypeKey] = {
        courses: []
      }
    }
    
    return courseTypesListCourses;
  };
  
  private static isValidCourseType (courseType: CourseType): boolean {
    if (courseType >= 0 && courseType <= 11) {
      return true;
    } else {
      return false;
    }
  };

  public static getAllCourseTypes (): CourseType[] {
    return courseTypeMap;
  }
  
  public static getCourseTypeByFieldName (remoteSettingsFieldName: string): number {
    switch (remoteSettingsFieldName) {
      case "bestSellerCorsiExecutive":
      return CourseType.CorsiExecutive;
      
      case "bestSellerCorsiLaurea":
      return CourseType.CorsiLaurea;
      
      case "bestSellerCorsiOnline":
      return CourseType.CorsiOnline;
      
      case "bestSellerExecutiveMaster":
      return CourseType.ExecutiveMaster;
      
      case "bestSellerExecutiveMBA":
      return CourseType.ExecutiveMBA;
      
      case "bestSellerMasterPostLaureaConStage":
      return CourseType.MasterPostLaureaConStage;
      
      case "bestSellerMasterclass":
      return CourseType.Masterclass;
      
      case "bestSellerMBAFullTime":
      return CourseType.MBAFullTime;
      
      case "bestSellerMBAPartTime":
      return CourseType.MBAPartTime;
      
      case "bestSellerProfessionalMaster":
      return CourseType.ProfessionalMaster;
      
      case "bestSellerStudyTour":
      return CourseType.StudyTour;
      
      case "bestSellerMasterOnline":
      return CourseType.MasterOnline;
      
      
      default:
      return -1;
    }
  };
  
  public static getCourseTypeByCourseTypeName (courseTypeName: string): number {
    switch (courseTypeName.toLowerCase()) {
      case "corsi executive":
      return CourseType.CorsiExecutive;
      
      case "corsi laurea":
      case "corsi di laurea":
      return CourseType.CorsiLaurea;
      
      case "corsi on demand":
      case "corsi ondemand":
      case "corsi on line":
      case "corsi online":
      return CourseType.CorsiOnline;
      
      case "executive master":
      case "master executive":
      return CourseType.ExecutiveMaster;
      
      case "executive mba":
      case "emba":
      return CourseType.ExecutiveMBA;
      
      case "master post laurea con stage":
      case "master post laurea con stage garantito":
      case "master post laurea":
      return CourseType.MasterPostLaureaConStage;
      
      case "masterclass":
      return CourseType.Masterclass;
      
      case "mba full time":
      case "master mba full time":
      return CourseType.MBAFullTime;
      
      case "mba part time":
      case "master mba part time":
      return CourseType.MBAPartTime;
      
      case "master professional":
      case "professional master":
      return CourseType.ProfessionalMaster;
      
      case "study tour":
      case "studytour":
      return CourseType.StudyTour;
      
      case "master online":
      case "master on line":
      return CourseType.MasterOnline;
      
      default:
      return -1;
    }
  };
  
  public static isFreeCancellationDateValidByCourseTypeName (courseTypeName: string, date: Date): boolean {
    
    const now = new Date();
    const cancellationDays = CourseTypeStore.getCancellationDaysByCourseTypeName(courseTypeName);
    
    if(cancellationDays<=0 || !date || date.getTime()<=now.getTime()) {
      return false;
    }
    const limit = CourseTypeStore.getCancellationDateByCourseTypeName(courseTypeName,date);
    
    if(limit.getTime()>now.getTime()) {
      return true;
    }
    else{
      return false;
    }
  };
  
  public static getCancellationDateByCourseTypeName (courseTypeName: string, date: Date): Date {
    
    const now = new Date();
    if(!date || date.getTime()<=now.getTime()) {
      return now;
    }
    return new Date( date.getTime() - (CourseTypeStore.getCancellationDaysByCourseTypeName(courseTypeName) * 24 * 60 * 60 * 1000) );
  }
  
  
  public static getCancellationDaysByCourseTypeName (courseTypeName: string): number {
    
    // TODO: review with new course types
    switch (courseTypeName.toLowerCase()) {
      case "master full time con stage garantito":
      case "master full time con stage":
      case "master full time":
      case "master full time con career service":
      // - 21 days
      return 21;
      
      case "master part time":
      // - 14 days
      return 14;
      
      case "master online":
      // - 14 days
      return 14;
      
      case "master executive":
      case "master mba":
      // - 14 days
      return 14;
      
      case 'master mba full time':
      case 'mba full time':
      // -21 days
      return 21;
      
      case 'corsi intensivi con stage garantito':
      case 'corsi intensivi con stage':
      case 'corsi full time':
      case 'corsi intensivi':
      //coding-academy is a corsi intensivi con stage
      //- 21days
      return 21;
      
      default:
      return -1;
    }
  }
  
  public static getCourseTypeByCourseTypeSlug (courseTypeSlug: string): number {
    switch (courseTypeSlug) {
      
      case stringToSlug("Corsi Executive"):
      return CourseType.CorsiExecutive;
      
      case stringToSlug("Corsi Laurea"):
      case stringToSlug("Corsi di Laurea"):
      return CourseType.CorsiLaurea;
      
      case stringToSlug("Corsi On Demand"):
      case stringToSlug("Corsi Ondemand"):
      case stringToSlug("Corsi Online"):
      return CourseType.CorsiOnline;
      
      case stringToSlug("Executive Master"):
      case stringToSlug("Master Executive"):
      return CourseType.ExecutiveMaster;
      
      case stringToSlug("Executive MBA"):
      case stringToSlug("EMBA"):
      return CourseType.ExecutiveMBA;
      
      case stringToSlug("Master Post Laurea con Stage"):
      case stringToSlug("Master Post Laurea con Stage Garantito"):
      case stringToSlug("Master Post Laurea"):
      return CourseType.MasterPostLaureaConStage;
      
      case stringToSlug("Masterclass"):
      return CourseType.Masterclass;
      
      case stringToSlug("MBA Full Time"):
      case stringToSlug("Master MBA Full Time"):
      return CourseType.MBAFullTime;
      
      case stringToSlug("MBA Part Time"):
      case stringToSlug("Master MBA Part Time"):
      return CourseType.MBAPartTime;
      
      case stringToSlug("Master Professional"):
      case stringToSlug("Professional Master"):
      return CourseType.ProfessionalMaster;
      
      case stringToSlug("Study Tour"):
      case stringToSlug("StudyTour"):
      return CourseType.StudyTour;
      
      case stringToSlug("Master Online"):
      case stringToSlug("MasterOnline"):
      return CourseType.MasterOnline;
      
      default:
      return -1;
    }
  };
  
  public static getCourseTypeFullNameByCourseTypeSlug (courseTypeSlug: string): string {
    switch (courseTypeSlug) {
      
      case stringToSlug("Corsi Executive"):
      return "Corsi Executive";
      
      case stringToSlug("Corsi Laurea"):
      case stringToSlug("Corsi di Laurea"):
      return "Corsi di Laurea";
      
      case stringToSlug("Corsi On Demand"):
      case stringToSlug("Corsi Ondemand"):
      case stringToSlug("Corsi Online"):
      return "Corsi Online";
      
      case stringToSlug("Executive Master"):
      case stringToSlug("Master Executive"):
      return "Executive Master";
      
      case stringToSlug("Executive MBA"):
      case stringToSlug("EMBA"):
      return "Executive MBA";
      
      case stringToSlug("Master Post Laurea con Stage"):
      case stringToSlug("Master Post Laurea con Stage Garantito"):
      case stringToSlug("Master Post Laurea"):
      return "Master Post Laurea con Stage";
      
      case stringToSlug("Masterclass"):
      return "Masterclass";
      
      case stringToSlug("MBA Full Time"):
      case stringToSlug("Master MBA Full Time"):
      return "MBA Full Time";
      
      case stringToSlug("MBA Part Time"):
      case stringToSlug("Master MBA Part Time"):
      return "MBA Part Time";
      
      case stringToSlug("Master Professional"):
      case stringToSlug("Professional Master"):
      return "Professional Master";
      
      case stringToSlug("Study Tour"):
      case stringToSlug("StudyTour"):
      return "Study Tour";
      
      case stringToSlug("Master Online"):
      case stringToSlug("MasterOnline"):
      return "Master Online";
      
      default:
      return "";
    }
  };
  
  /**
  * Get course type slug by course type
  * @param number courseType
  * @return string
  */
  public static getCourseTypeSlugByCourseType (courseType: number): string {
    switch (courseType) {
      case CourseType.CorsiExecutive:
      return stringToSlug("Corsi Executive");
      
      case CourseType.CorsiLaurea:
      return stringToSlug("Corsi Laurea");
      
      case CourseType.CorsiOnline:
      return stringToSlug("Corsi Online");
      
      case CourseType.ExecutiveMaster:
      return stringToSlug("Executive Master");
      
      case CourseType.ExecutiveMBA:
      return stringToSlug("Executive MBA");
      
      case CourseType.MasterPostLaureaConStage:
      return stringToSlug("Master Post Laurea con Stage");
      
      case CourseType.Masterclass:
      return stringToSlug("Masterclass");
      
      case CourseType.MBAFullTime:
      return stringToSlug("MBA Full Time");
      
      case CourseType.MBAPartTime:
      return stringToSlug("MBA Part Time");
      
      case CourseType.ProfessionalMaster:
      return stringToSlug("Professional Master");
      
      case CourseType.StudyTour:
      return stringToSlug("Study Tour");
      
      case CourseType.MasterOnline:
      return stringToSlug("Master Online");
      
      default:
      return "";
    }
  }
  
  public static isMaster (courseTypeSlug: string): boolean {
    switch (courseTypeSlug) {
      // case CourseType. stringToSlug('Master Post Laurea con Stage'):
      // case stringToSlug('Executive Master'):
      // case stringToSlug('Professional Master'):
      // case stringToSlug('Executive MBA'):
      // case stringToSlug('MBA Full Time'):
      // case stringToSlug('MBA Part Time'):
      //     return true;
      
      // case stringToSlug('Corsi Executive'):
      // case stringToSlug('Corsi Laurea'):
      // case stringToSlug('Corsi Online'):
      // case stringToSlug('Masterclass'):
      //     return false;
      
      default:
      return false;
    }
  };
  
  /**
  * Get course type css class by course type
  * @param number courseType
  * @return string
  */
  public static getCourseTypeCssClassByCourseType (courseType: number): string {
    switch (courseType) {
      case CourseType.CorsiExecutive:
      return "corsiExecutiveCoursesColor";
      
      case CourseType.CorsiLaurea:
      return "corsiLaureaCoursesColor";
      
      case CourseType.CorsiOnline:
      return "corsiOnlineCoursesColor";
      
      case CourseType.ExecutiveMaster:
      return "executiveMasterCoursesColor";
      
      case CourseType.ExecutiveMBA:
      return "executiveMBACoursesColor";
      
      case CourseType.MasterPostLaureaConStage:
      return "masterPostLaureaCoursesColor";
      
      case CourseType.Masterclass:
      return "masterclassCoursesColor";
      
      case CourseType.MBAFullTime:
      return "MBAFullTimeCoursesColor";
      
      case CourseType.MBAPartTime:
      return "MBAPartTimeCoursesColor";
      
      case CourseType.ProfessionalMaster:
      return "professionalMasterCoursesColor";
      
      case CourseType.StudyTour:
      return "studyTourCoursesColor";
      
      case CourseType.MasterOnline:
      return "masterOnlineCoursesColor";
      
      default:
      return "defaultCoursesColor";
    }
  }
}