import { PUBLIC_COURSES_DB_URL, PUBLIC_INSTRUCTORS_DB_URL, PUBLIC_PARTNERS_DB_URL, PUBLIC_STATIC_ASSETS_BASE_URL, PUBLIC_BROCHURE_BASE_URL, PUBLIC_FORCE_TO_WEBP } from '$env/static/public';
import { CoursesDb, InstructorsDb, PartnersDb } from '@24hbs/utils';
import type { AssetsConfig, CourseModel, SessionModel } from '@24hbs/utils';

const COURSES_DB_URL = PUBLIC_COURSES_DB_URL;
const INSTRUCTORS_DB_URL = PUBLIC_INSTRUCTORS_DB_URL;
const PARTNERS_DB_URL = PUBLIC_PARTNERS_DB_URL;

const defaultQuery = '?r=' + Math.floor(Math.random() * 100000000);

function buildDbUrl(baseUrl: string, query: string = defaultQuery) {
  return `${baseUrl}${query}`;
}

export function getAssetsConfig(): AssetsConfig {
  const staticAssetsBaseUrl =
    PUBLIC_STATIC_ASSETS_BASE_URL || '';
  const brochureBaseUrl = PUBLIC_BROCHURE_BASE_URL || '';
  const forceToWebp = PUBLIC_FORCE_TO_WEBP === 'true';

  return {
    staticAssetsBaseUrl,
    brochureBaseUrl,
    forceToWebp,
  };
}

export class DbUtils {
  // Courses
  static async loadCourses(fetch : {
    (input: RequestInfo | URL, init?: RequestInit): Promise<Response>;
    (input: string | URL | globalThis.Request, init?: RequestInit): Promise<Response>;
  }, force = false) {
    return CoursesDb.loadDb(
      fetch,
      buildDbUrl(COURSES_DB_URL),
      force,
      getAssetsConfig()
    );
  }

  static exportCoursesDb() {
    return CoursesDb.exportDb();
  }

  static getAllSessions() {
    const courses: CourseModel[] = Object.values(JSON.parse(DbUtils.exportCoursesDb()));

    let sessions: SessionModel[] = [];
    courses.forEach((course) => {
      sessions = sessions.concat(Object.values(course.sessions));
    });

    if (!sessions) {
      return [];
    }

    return sessions;
  }

  static getCourse(slug: string) {

    return CoursesDb.getCourse(slug);
  }

  static getCourseByProductCode(productCode: string) {
    return CoursesDb.getCourseByProductCode(productCode);
  }

  // Instructors
  static async loadInstructors(fetch: {
    (input: RequestInfo | URL, init?: RequestInit): Promise<Response>;
    (input: string | URL | globalThis.Request, init?: RequestInit): Promise<Response>;
  }) {
    return InstructorsDb.loadDb(
      fetch,
      buildDbUrl(INSTRUCTORS_DB_URL),
      getAssetsConfig()
    );
  }

  static exportInstructrsDb() {
    return InstructorsDb.exportDb();
  }

  static getInstructorById(instructorId: number) {
    return InstructorsDb.getInstructorById(instructorId);
  }

  static getInstructor(slug: string) {
    return InstructorsDb.getInstructor(slug);
  }

  // Partners
  static async loadPartners(fetch: {
    (input: RequestInfo | URL, init?: RequestInit): Promise<Response>;
    (input: string | URL | globalThis.Request, init?: RequestInit): Promise<Response>;
  }) {
    return PartnersDb.loadDb(
      fetch,
      buildDbUrl(PARTNERS_DB_URL),
      getAssetsConfig()
    );
  }

  static exportPartnersDb() {
    return PartnersDb.exportDb();
  }

  static getPartnerById(partnerId: number) {
    return PartnersDb.getPartnerById(partnerId);
  }

  static getPartner(slug: string) {
    return PartnersDb.getPartner(slug);
  }
}
