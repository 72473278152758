const listingsByCourseTypes = {

  "corsi-executive": {
    "listingPage": "CorsiExecutive",
    "in": {
      "courseTypes": ["CorsiExecutive"],
      "filters": {}
    },
    "hero": {
      "type": "Corsi Executive",
      "title": "Individua il tema più in linea con le tue necessità di aggiornamento. Perfeziona, integra e sviluppa le tue competenze per svolgere al meglio il tuo ruolo.",
      "content": "I Corsi Executive permettono di consolidare e aggiornare <strong>competenze specifiche</strong> necessarie per <strong>crescere professionalmente</strong> nell'ambito desiderato. Si caratterizzano per formazione in aula e/o in live streaming nel weekend o in giornate infrasettimanali, con docenti qualificati ed esperti, esercitazioni pratiche con professionisti e manager di aziende, casi di studio e rilascio finale di un attestato.​"
    }
  },

  "corsi-online": {
    "listingPage": "CorsiOnline",
    "in": {
      "courseTypes": [
        "CorsiOnline", 
        "MasterOnline"
      ],
      "filters": {
        "lessionMode": [
          "On Demand"
        ]
      }
    },
    "hero": {
      "type": "Corsi Online",
      "title": "Videolezioni ed esercitazioni per studiare quando vuoi. Podcast e materiali didattici da tenere sempre con te.",
      "content": "24ORE Business School propone una vasta gamma di <strong>Corsi Online</strong>, pratici e veloci per studiare quando, dove e ovunque ci si trovi. I Corsi Online sono rivolti a Manager, Professionisti e Studenti che vogliono approfondire aree di business specifiche. Prevedono video lezioni, podcast, test di valutazione finale e il rilascio di un <strong>attestato</strong>."
    }
  },

  "executive-master": {
    "listingPage": "ExecutiveMaster",
    "in": {
      "courseTypes": ["ExecutiveMaster"],
      "filters": {}
    },
    "hero": {
      "type": "Executive Master",
      "title": "Per sviluppare e consolidare skills tecniche e competenze manageriali.",
      "content": "Gli Executive Master sono percorsi <strong>innovativi</strong> e <strong>altamente professionalizzanti</strong> che garantiscono acquisizione e aggiornamento di <strong>skills tecniche</strong> e <strong>competenze manageriali</strong> necessarie a chi ricopre posizioni apicali nelle imprese e nel mondo professionale o a chi si prepara a farlo. Progettati in collaborazione con le più importanti e note aziende e società di consulenza, si caratterizzano per l'aggiornamento continuo e una <strong>didattica interattiva</strong> arricchita da <strong>esercitazioni</strong>, <strong>case studies</strong> e <strong>giornate di life skills</strong>.​ Le docenze si svolgono in aula e/o in Live Streaming, con accesso da qualsiasi dispositivo e con la possibilità di rivedere le lezioni registrate.​"
    }
  },

  "master-full-time": {
    "listingPage": "MasterPostLaureaConStage",
    "in": {
      "courseTypes": ["MasterPostLaureaConStage"],
      "filters": {}
    },
    "hero": {
      "type": "Master Post Laurea con Stage garantito",
      "title": "Per giovani neolaureati che vogliono accelerare l’ingresso nel mondo del lavoro",
      "content": "I <strong>Master Post Laurea con Stage garantito</strong> ti permettono di <strong>accrescere le tue conoscenze</strong> e <strong>consolidare le tue basi</strong> per entrare nel mondo del lavoro. Puoi frequentare <strong>in aula</strong> oppure <strong>in live streaming</strong>, per seguire le lezioni da dove vuoi e dal device che preferisci (computer, smartphone, tablet e smart TV), mantenendo sempre un <strong>altissimo livello di interazione</strong> con docenti e colleghi, anche durante le simulazioni, i project work e le esercitazioni su casi reali. È previsto uno stage formativo presso le oltre 1.300 aziende partner. I Master, a tempo pieno, numero chiuso e frequenza obbligatoria, hanno una durata complessiva tra i 10 e i 12 mesi, di cui 6 di stage."
    }
  },

  "masterclass": {
    "listingPage": "Masterclass",
    "in": {
      "courseTypes": ["Masterclass"],
      "filters": {}
    },
    "hero": {
      "type": "Masterclass",
      "title": "Formazione smart per aziende e professionisti",
      "content": "Con le <strong>Masterclass</strong>, 24ORE Business School vuole creare un’occasione unica per aziende, liberi professionisti o per chi vuole avvicinarsi alle diverse tematiche proposte, di incontrare i migliori esperti in materia attraverso <strong>sessioni formative brevi, smart, flessibili e orientate al business.</strong> Inoltre, saranno integrate da presentazioni di casi di successo e best practices da cui trarre spunto."
    }
  },

  "professional-master": {
    "listingPage": "ProfessionalMaster",
    "in": {
      "courseTypes": ["ProfessionalMaster"],
      "filters": {}
    },
    "hero": {
      "type": "Professional Master",
      "title": "Un’occasione unica di crescita professionale. Scegli uno dei percorsi più affini alla tua carriera e arricchisci la tua formazione grazie a un network di professionisti.",
      "content": "I Professional Master sono percorsi di specializzazione in determinati ambiti disciplinari, settori professionali o funzioni aziendali.​ Sono pensati appositamente per <strong>conciliare formazione e lavoro</strong> grazie alla <strong>formula weekend</strong>, con lezioni il venerdì e il sabato, oppure in <strong>formula serale</strong>.​ Lezioni ed esercitazioni, project work e testimonianze di manager e professionisti possono essere seguiti <strong>in presenza o in live streaming</strong>, attraverso un'avanzata piattaforma di e-learning (fruibile da qualsiasi device) che permette di vivere un altissimo livello di interazione con docenti e colleghi."
    }
  },

  "master-part-time": {
    "listingPage": "MasterPartTime",
    "in": {
      "courseTypes": ["ExecutiveMaster", "ProfessionalMaster", "CorsiExecutive"],
      "filters": {}
    },
    "hero": {
      "type": "Master Executive Part Time",
      "title": "12 aree tematiche, più di 200 master e un network di eccellenza per soddisfare tutte le esigenze di reskilling e upskillig e accelerare il proprio percorso di carriera.",
      "content": "I <strong>Master Executive Part Time</strong> sono progettati per consentire a manager e professionisti di aggiornarsi. La metodologia «<strong>business oriented</strong>» consente di acquisire strumenti avanzati subito spendibili nel proprio contesto lavorativo. <strong>Le diverse formule di fruizione</strong>, part time e online, in aula o in live streaming, garantiscono la <strong>massima flessibilità e conciliabilità</strong> con i propri impegni personali e professionali."
    }
  },

  "mba-emba": {
    "listingPage": "MBA e EMBA",
    "in": {
      "courseTypes": ["MBAFullTime", "MBAPartTime", "ExecutiveMBA"],
      "filters": {}
    },
    "hero": {
      "type": "MBA e EMBA",
      "title": "Un’esperienza unica e irripetibile di trasformazione personale e professionale. Investi su te stesso e dai un boost alla tua carriera.",
      "content": "I <strong>Master in Business Administration</strong> sono <strong>percorsi innovativi</strong> e di alto profilo che garantiscono le competenze manageriali necessarie a chi ha l'ambizione di <strong>dirigere organizzazioni aziendali</strong>. Progettati in collaborazione con le aziende e società di consulenza partner si caratterizzano per l'aggiornamento continuo e l'aderenza ai cambiamenti globali. Il Network con manager e colleghi di master si integra con una didattica interattiva arricchita da Project Work, Case Studies, CEO Talks, Team Building e Company Visit. Le docenze si svolgono in aula e in Live Streaming, da qualsiasi dispositivo con la possibilità di rivedere le lezioni registrate."
    }
  },

  "master-universitari-con-cfu": {
    "listingPage": "​ProfessionalMasterCFU",
    "in": {
      "courseTypes": [
        "CorsiExecutive",
        "CorsiLaurea",
        "ExecutiveMaster",
        "ExecutiveMBA",
        "MasterPostLaureaConStage",
        "Masterclass",
        "MBAFullTime",
        "MBAPartTime",
        "ProfessionalMaster",
        "StudyTour"
      ],
      "filters": {
        "certificationReleaseEntity": [
          "UniMarconi - prodotto BS24",
          "UniMarconi - prodotto UniMarconi",
          "Unitelma"
        ]
      }
    },
    "hero": {
      "type": "Professional Master con CFU",
      "title": "Opportunità post-laurea per l'acquisizione di competenze e il consolidamento della propria carriera",
      "content": "I <strong>Professional Master Universitari</strong>, organizzati in partnership con l'<strong>Università Guglielmo Marconi</strong> e l'<strong>Università Unitelma Sapienza</strong>, sono percorsi di specializzazione, dedicati a coloro che hanno già conseguito una laurea triennale o magistrale. Costituiscono un'<strong>opportunità di crescita importante</strong> per associare a una formazione pratica e operativa il contributo scientifico universitario, con il conseguimento del Diploma universitario di 1° o 2° livello e 60 CFU. I Master universitari aiutano i neolaureati ad avvicinarsi <strong>al mondo del lavoro</strong> e a migliorare le proprie prospettive occupazionali e <strong>favoriscono i giovani professionisti</strong> che vogliono rilanciare il proprio percorso di carriera. Le formule Part Time sono disponibili in live-streaming, fruibili con la massima flessibilità nella scelta di come, dove e quando seguire le lezioni."
    }
  },

  "master-euroconference": {
    "listingPage": "CorsiEuroconference",
    "in": {
      "courseTypes": [
        "CorsiExecutive",
        "CorsiLaurea",
        "CorsiOnline",
        "ExecutiveMaster",
        "ExecutiveMBA",
        "MasterPostLaureaConStage",
        "Masterclass",
        "MBAFullTime",
        "MBAPartTime",
        "ProfessionalMaster",
        "StudyTour"
      ],
      "filters": {
        "certificationReleaseEntity": [
          "Euroconference"
        ]
      }
    },
    "hero": {
      "type": "Corsi Euroconference",
      "title": "L'accesso privilegiato all'expertise dei migliori professionisti su Lavoro, Fisco e Legal, per il tuo successo professionale",
      "content": "Il catalogo prodotti <strong>Euroconference</strong> ti offre percorsi formativi di alta qualità suddivisi in tre aree tematiche: <strong>Legale, Fiscale</strong> e <strong>Lavoro</strong>. La nostra proposta ti permette di accelerare la tua crescita professionale, <strong>acquisire competenze specializzate e rimanere sempre aggiornato sulle ultime novità</strong>, ottenendo anche crediti formativi. La possibilità di interagire direttamente con i docenti e la flessibilità dei formati di fruizione rendono i prodotti Euroconference la scelta ideale per il tuo sviluppo professionale. Siamo il punto di riferimento per la tua formazione legale, fiscale e lavoro, e ti offriamo soluzioni formative all'avanguardia per restare sempre competente nel tuo settore."
    }
  }
}

const listingsByCategory = {
  "ambiente-energia-e-pa": {
    "listingPage": "AmbienteEnergiaPA",
    "in": {
      "courseCategory": "AmbienteEnergiaPA",
      "filters": {}
    },
    "hero": {
      "image": "/assets/images/categories/ambiente-energia-e-pa.webp",
      "srcset": "/assets/images/categories/ambiente-energia-e-pa.webp",
      "imageAlt": "Ambiente, Energia e P.A.",
      "name": "Ambiente, Energia e P.A.",
      "quote": "I nostri Master e Corsi di Ambiente, Energia e P.A.",
      "text": "I <strong>Master</strong> e <strong>Corsi</strong> in <strong>Pubblica Amministrazione</strong>, <strong>Appalti Pubblici</strong> e <strong>Sostenibilità</strong> e <strong>Gestione Ambientale</strong> di 24ORE Business School si svolgono a Milano, Roma o interamente Online. Master Full Time e Part Time certificati per sviluppare competenze nella digitalizzazione della Pubblica Amministrazione, nella Gestione di Appalti, Forniture e Lavori Pubblici o nel settore della <strong>Green Economy</strong>."
    }
  },
  "amministrazione-finanza-e-controllo": {
    "listingPage": "AmministrazioneFinanzaControllo",
    "in": {
      "courseCategory": "AmministrazioneFinanzaControllo",
      "filters": {}
    },
    "hero": {
      "image": "/assets/images/categories/amministrazione-finanza-e-controllo.webp",
      "srcset": "/assets/images/categories/amministrazione-finanza-e-controllo.webp",
      "imageAlt": "Amministrazione, Finanza e Controllo",
      "name": "Amministrazione, Finanza e Controllo",
      "quote": "I nostri Master e Corsi di Amministrazione, Finanza e Controllo",
      "text": "24ORE Business School offre una vasta scelta formativa di <strong>Master</strong> in <strong>Amministrazione</strong>, <strong>Finanza e Controllo di Gestione</strong>, <strong>Banking</strong> e <strong>Fintech</strong> o <strong>Credit Management</strong>. Formazione certificata in modalità Executive, Full Time con stage, Part Time ed Online per acquisire competenze tecniche nella <strong>gestione del bilancio</strong>, nella scelta degli strumenti di <strong>private e corporate banking</strong> o nel <strong>risk management</strong>."
    }
  },
  "arte-cinema-e-design": {
    "listingPage": "ArteCinemaDesign",
    "in": {
      "courseCategory": "ArteCinemaDesign",
      "filters": {}
    },
    "hero": {
      "image": "/assets/images/categories/arte-cinema-e-design.webp",
      "srcset": "/assets/images/categories/arte-cinema-e-design.webp",
      "imageAlt": "Arte, Cinema e Design",
      "name": "Arte, Cinema e Design",
      "quote": "I nostri Master e Corsi di Arte, Cinema e Design",
      "text": "I <strong>Master</strong> in <strong>Arte</strong>, Cinema e Design di 24ORE Business School si svolgono a Roma, Milano o in modalità Live Streaming. I <strong>Master Full Time con stage</strong> o <strong>Part Time</strong> sono rivolti a giovani laureati o neolaureandi che vogliono fare carriera nel <strong>settore Audiovisivo</strong> e <strong>del Media Entertainment</strong>. Un network di eccellenza, project work con rinomate case di <strong>produzione cinematografica</strong> e company tour completano l'offerta formativa."
    }
  },
  "coding-academy": {
    "listingPage": "CodingAcademy",
    "in": {
      "courseCategory": "CodingAcademy",
      "filters": {}
    },
    "hero": {
      "image": "/assets/images/categories/coding-academy.webp",
      "srcset": "/assets/images/categories/coding-academy.webp",
      "imageAlt": "Coding Academy",
      "name": "Coding Academy",
      "quote": "Esplora i corsi di una Coding Academy all'avanguardia e trasforma la tua passione per il mondo Tech in opportunità concrete nel mondo del lavoro",
      "text": "La Coding Academy proiettata al futuro per prepararti alle sfide del mondo Tech. Un’esperienza formativa altamente pratica e incentrata sul digitale. Potrai partecipare alle lezioni in live streaming utilizzando il tuo dispositivo preferito (computer, smartphone, tablet, smart TV) ed interagendo attivamente con docenti e gli altri partecipanti, anche durante le simulazioni, i project work e le esercitazioni su casi reali. Che tu sia un principiante o un esperto del settore, la Coding Academy ti offre un ambiente stimolante e innovativo per imparare i linguaggi della programmazione ed aprirti le porte a una carriera di successo nel settore tecnologico.24ORE Business School propone <strong>Master</strong> e <strong>Corsi</strong> di <strong>Coding Academy</strong> a Milano, Roma ed online. Master in <strong>Web Development</strong>, <strong>Web Design</strong>, <strong>Web Marketing</strong>, <strong>Web App Development</strong> e <strong>Web Design e Development</strong>. A scelta tra Master Executive, Full Time con stage, Part Time ed Online."
    }
  },
  "digital-academy": {
    "listingPage": "DigitalAcademy",
    "in": {
      "courseCategory": "DigitalAcademy",
      "filters": {}
    },
    "hero": {
      "image": "/assets/images/categories/digital-academy.webp",
      "srcset": "/assets/images/categories/digital-academy.webp",
      "imageAlt": "Digital Academy",
      "name": "Digital Academy",
      "quote": "Per esplorare le opportunità professionali nel marketing con un apprendimento votato al futuro e connesso con il mondo del lavoro",
      "text": "La Digital Academy rivoluziona le competenze di marketing con un approccio futuro-oriented e strettamente collegato al mondo del lavoro. Preparandoti alle sfide del marketing digitale, i nostri corsi garantiscono programmi innovativi e l’opportunità di uno stage. Un’esperienza formativa altamente pratica e incentrata sul digitale. Potrai partecipare alle lezioni in live streaming utilizzando il tuo dispositivo preferito (computer, smartphone, tablet, smart TV) ed interagendo attivamente con docenti e compagni, anche durante le simulazioni, i project work e le esercitazioni su casi reali. La Digital Academy ti offre risorse avanzate e il contatto diretto ad una vasta rete di professionisti del settore."
    }
  },
  "diritto-e-fisco": {
    "listingPage": "DirittoFisco",
    "in": {
      "courseCategory": "DirittoFisco",
      "filters": {}
    },
    "hero": {
      "image": "/assets/images/categories/diritto-e-fisco.webp",
      "srcset": "/assets/images/categories/diritto-e-fisco.webp",
      "imageAlt": "Diritto e Fisco",
      "name": "Diritto e Fisco",
      "quote": "I nostri Master e Corsi di Diritto e Fisco",
      "text": "Una vasta gamma di Master e Corsi di formazione a Milano, Roma ed online che 24ORE Business School propone a neolaureati e professionisti. <strong>Master in Diritto Tributario</strong>, <strong>Master in Diritto Tributario</strong> e d’Impresa, <strong>Diritto Bancario</strong> ma anche <strong>Contabilità e Bilancio</strong> e <strong>Data Protection</strong>. A scelta tra Master Executive, Full Time con stage, Part Time ed Online."
    }
  },
  "food-beverage-e-turismo": {
    "listingPage": "FoodBeverageTurismo",
    "in": {
      "courseCategory": "FoodBeverageTurismo",
      "filters": {}
    },
    "hero": {
      "image": "/assets/images/categories/food-beverage-e-turismo.webp",
      "srcset": "/assets/images/categories/food-beverage-e-turismo.webp",
      "imageAlt": "Food, Beverage e Turismo",
      "name": "Food, Beverage & Turismo",
      "quote": "I nostri Master e Corsi di Food, Beverage e Turismo",
      "text": "I nostri <strong>Master in Turismo, Food and Beverage, Hotel Management</strong> permettono di acquisire competenze e tecniche innovative per diventare esperto in <strong>Food Marketing, Marketing Turistico & Hotellerie</strong>, aree di eccellenza del Made in Italy. 24ORE Business School offre formazione in aula o in Live Streaming a Milano con Master Full Time con stage o Part Time."
    }
  },
  "gestione-di-impresa-export-e-internazionalizzazione": {
    "listingPage": "GestioneImpresaExport",
    "in": {
      "courseCategory": "GestioneImpresaExport",
      "filters": {}
    },
    "hero": {
      "image": "/assets/images/categories/gestione-di-impresa-export-e-internazionalizzazione.webp",
      "srcset": "/assets/images/categories/gestione-di-impresa-export-e-internazionalizzazione.webp",
      "imageAlt": "Gestione di Impresa, Export e Internazionalizzazione",
      "name": "Gestione di Impresa, Export e Internazionalizzazione",
      "quote": "I nostri Master e Corsi di Gestione di Impresa, Export e Internazionalizzazione",
      "text": "Una vasta gamma di <strong>Master</strong> e <strong>Corsi</strong> di formazione 24ORE Business School in <strong>Gestione d'Impresa</strong>, <strong>Export</strong> o <strong>Project Management</strong> a Milano, Roma o interamente Online. Master Full Time con stage, Part Time ed Executive per formare Imprenditori, Export Manager, Manager d'azienda con competenze tecniche certificate di alto livello."
    }
  },
  "hr-lavoro-e-sviluppo-manageriale": {
    "listingPage": "HRLavoroSviluppoManageriale",
    "in": {
      "courseCategory": "HRLavoroSviluppoManageriale",
      "filters": {}
    },
    "hero": {
      "image": "/assets/images/categories/hr-lavoro-e-sviluppo-manageriale.webp",
      "srcset": "/assets/images/categories/hr-lavoro-e-sviluppo-manageriale.webp",
      "imageAlt": "Hr, Lavoro e Sviluppo Manageriale",
      "name": "Hr, Lavoro e Sviluppo Manageriale",
      "quote": "I nostri Master e Corsi di HR, Lavoro e Sviluppo Manageriale",
      "text": "Master e Corsi in <strong>Human Resources, HR Management, Gestione delle Risorse Umane e Diritto del lavoro</strong>, sono alcune delle migliori offerte di formazione della 24ORE Business School. Master Executive, Full Time con Stage, Part Time ed Online per sviluppare strumenti e competenze nel mercato del lavoro e recruiting per diventare <strong>HR Manager</strong> o <strong>HR Specialist</strong>. Formazione in Streaming e in Aula a Milano e Roma."
    }
  },
  "lusso-e-moda": {
    "listingPage": "LussoModa",
    "in": {
      "courseCategory": "LussoModa",
      "filters": {}
    },
    "hero": {
      "image": "/assets/images/categories/lusso-e-moda.webp",
      "srcset": "/assets/images/categories/lusso-e-moda.webp",
      "imageAlt": "Lusso e Moda",
      "name": "Lusso e Moda",
      "quote": "I nostri Master e Corsi di Lusso e Moda",
      "text": "La più ampia offerta formativa di Master e Corsi di <strong>Fashion Marketing</strong> e <strong>Luxury Management</strong>. Master di <strong>Moda</strong> Full Time, Part Time e Online a Milano e Roma. La frequenza dei nostri master in <strong>Fashion e Luxury</strong> può avvenire in aula o in streaming. <strong>Fashion Buying, Design e Comunicazione di Moda, Marketing e Brand Strategy</strong> per diventare un vero professionista del settore Luxury con 24ORE Business School."
    }
  },
  "marketing-comunicazione-digital-e-social-media": {
    "listingPage": "MarketingComunicazioneDigitalSocial",
    "in": {
      "courseCategory": "MarketingComunicazioneDigitalSocial",
      "filters": {}
    },
    "hero": {
      "image": "/assets/images/categories/marketing-comunicazione-digital-e-social-media.webp",
      "srcset": "/assets/images/categories/marketing-comunicazione-digital-e-social-media.webp",
      "imageAlt": "Marketing, Comunicazione, Digital e Social Media",
      "name": "Marketing, Comunicazione, Digital e Social Media",
      "quote": "I nostri Master e Corsi di Marketing, Comunicazione, Digital e Social Media",
      "text": "Master <strong>Part Time</strong> e <strong>Full Time con Stage</strong>, in presenza nelle sedi di Roma e Milano o in formula Live Streaming, e corsi On Demand per acquisire competenze di <strong>Digital e Social Media Marketing, Communication Management, Big Data</strong> e <strong>Digital Analytics</strong>. La formazione di 24ORE Business School garantirà una specializzazione fortemente richiesta dalle imprese interessate dalla digital transformation."
    }
  },
  "sanita-pharma-e-biomed": {
    "listingPage": "SanitaPharmaBiomed",
    "in": {
      "courseCategory": "SanitaPharmaBiomed",
      "filters": {}
    },
    "hero": {
      "image": "/assets/images/categories/sanita-pharma-e-biomed.webp",
      "srcset": "/assets/images/categories/sanita-pharma-e-biomed.webp",
      "imageAlt": "Sanità, Pharma e Biomed",
      "name": "Sanità, Pharma e Biomed",
      "quote": "I nostri Master e Corsi di Sanità, Pharma e Biomed",
      "text": "Un'ampia scelta formativa con i nostri Master in <strong>Management Sanitario</strong>, <strong>Marketing Farmaceutico</strong> o <strong>Economia e Management dei servizi sanitari</strong>. Formazione certificata in modalità Executive, Full Time con stage, Part Time ed Online per acquisire competenze specifiche e diventare esperto del settore sanitario e farmaceutico con 24ORE Business School."
    }
  },
  "sport": {
    "listingPage": "Sport",
    "in": {
      "courseCategory": "Sport",
      "filters": {}
    },
    "hero": {
      "image": "/assets/images/categories/sport.webp",
      "srcset": "/assets/images/categories/sport.webp",
      "imageAlt": "Sport",
      "name": "Sport",
      "quote": "I nostri Master e Corsi di Sport",
      "text": "Con i <strong>Master</strong> e <strong>Corsi in Sport Management</strong> di 24ORE Business School è possibile trasformare una passione in una professione. Master Full Time con stage, Part Time o interamente Online a Milano e Roma per avere una visione a 360 gradi dello <strong>Sport Business</strong> e imparare la <strong>Comunicazione</strong>, il <strong>Marketing dello Sport</strong>, la <strong>Gestione di Eventi Sportivi</strong> o i <strong>Diritti Media dello Sport</strong>. I master prevedono il rilascio di un certificato."
    }
  },
  "vendite-e-retail": {
    "listingPage": "VenditeRetail",
    "in": {
      "courseCategory": "VenditeRetail",
      "filters": {}
    },
    "hero": {
      "image": "/assets/images/categories/vendite-e-retail.webp",
      "srcset": "/assets/images/categories/vendite-e-retail.webp",
      "imageAlt": "Vendite e Retail",
      "name": "Vendite e Retail",
      "quote": "I nostri Master e Corsi di Vendite e Retail",
      "text": "Un'ampia scelta di Master in <strong>Retail, Marketing</strong> e <strong>Sales Management</strong> per acquisire tutte le competenze e tecniche di una strategia <strong>omnichannel</strong>, il <strong>Marketing Drive to Store</strong> e <strong>Supply Chain</strong>. Master Full Time con stage, Part Time o interamente Online a Milano e Roma."
    }
  }
}

export const listingPageData = {
  ...listingsByCourseTypes,
  ...listingsByCategory
}