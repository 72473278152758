import { CourseLightModel, ReviewModel, SessionLightModel } from "@24hbs/utils";
import { CourseModel } from "@24hbs/utils";
import type { ContentfulClientApi } from "contentful";
import { CourseCategoryStore } from "$lib/utils/courses/category-store";
import type { TopCoursesByCourseCategory } from "$lib/utils/courses/category-store/types";
import { CourseTypeStore } from "$lib/utils/courses/type-store";
import type { TopCoursesByCourseType } from "$lib/utils/courses/type-store/types";
import { HomePageStore } from "$lib/utils/homepage/store";
import { DbUtils } from "$lib/utils/db-utils";
import { ListingPageUtils } from "$lib/utils/listing-page";
import type { SettingsUtilsData } from "./types";
import type { HomePageSettingsData } from "$lib/utils/homepage/store/types";
import type { INews, IPromoBoxes, ISettings } from "$lib/utils/contentful/types";
import type { ITopCoursesByListingPage } from "$lib/utils/listing-page/types";

// TODO: remove singleton pattern and refactor heavily
/**
 * Settings utils class
 */
export class SettingsUtils {

  private static loaded = false;
  private static loadedCourses = false;

  private static settingsUtilsData: SettingsUtilsData;

  private static initSettingsUtilsData() {
    SettingsUtils.settingsUtilsData = {
      bestSellerCoursesProductCodes: [],
      topCoursesByCourseCategory: CourseCategoryStore.initTopCoursesByCourseCategory(),
      topCoursesByCourseType: CourseTypeStore.initTopCoursesByCourseType(),
      topCoursesByListingPage: ListingPageUtils.initTopCoursesByListingPage(),
      homePageSettingsData: <HomePageSettingsData>{
        trendCourses: [],
        popularCourses: [],
        aboutUsReviews: [],
        promoBox: null,
        news: [],
      },
      mostReadNews: [],
      newsListingPromoBox: null
    }
  }
  public static async preloadSettings(_fetch: {
    (input: RequestInfo | URL, init?: RequestInit): Promise<Response>;
    (input: string | URL | globalThis.Request, init?: RequestInit): Promise<Response>;
  }): Promise<SettingsUtilsData | undefined> {
    const url = "/api/v1/settings.json";
    let res = await _fetch(url);
    if (res.ok) {
      const settingsObj = await res.json();
      return <SettingsUtilsData>settingsObj;
    }

    return undefined;
  }

  /**
   * @deprecated Seem to be unused
   * @returns 
   */
  public static getBestSellerCoursesProductCodes(): string[] {
    return SettingsUtils.settingsUtilsData.bestSellerCoursesProductCodes;
  }

  public static getTopCoursesByCourseCategory(): TopCoursesByCourseCategory {
    return SettingsUtils.settingsUtilsData.topCoursesByCourseCategory;
  }

  /**
   * @deprecated Seem to be unused
   * @returns 
   */
  public static getTopCoursesByCourseType(): TopCoursesByCourseType {
    return SettingsUtils.settingsUtilsData.topCoursesByCourseType;
  }

  /**
   * @deprecated Seem to be unused
   * @returns 
   */
  public static getTopCoursesByListingPage(): ITopCoursesByListingPage {
    return SettingsUtils.settingsUtilsData.topCoursesByListingPage;
  }

  /**
 * @deprecated Seem to be unused
 * @returns 
 */
  public static getHomePageSettings(): HomePageSettingsData {
    return SettingsUtils.settingsUtilsData.homePageSettingsData;
  }

  /**
* @deprecated Seem to be unused
* @returns 
*/
  public static getMostReadNews(): INews[] {
    return SettingsUtils.settingsUtilsData.mostReadNews;
  }

  /**
* @deprecated Seem to be unused
* @returns 
*/
  public static getNewsListingPromoBox(): IPromoBoxes | null {
    return SettingsUtils.settingsUtilsData.newsListingPromoBox;
  }

  public static loadPreloadedSettings(settingsUtilsSourceData: any = {}): SettingsUtilsData {
    SettingsUtils.initSettingsUtilsData();
    if (!SettingsUtils.loaded || !SettingsUtils.loadedCourses) {
      const settingsUtilsData = <SettingsUtilsData>Object.assign({
        bestSellerCoursesProductCodes: [],
        topCoursesByCourseCategory: CourseCategoryStore.initTopCoursesByCourseCategory(),
        topCoursesByCourseType: CourseTypeStore.initTopCoursesByCourseType(),
        topCoursesByListingPage: ListingPageUtils.initTopCoursesByListingPage(),
        homePageSettingsData: HomePageStore.initHomePageSettingsData(),
        mostReadNews: [],
        newsListingPromoBox: null
      }, settingsUtilsSourceData);

      SettingsUtils.settingsUtilsData = {
        bestSellerCoursesProductCodes: settingsUtilsData?.bestSellerCoursesProductCodes,
        topCoursesByCourseCategory: CourseCategoryStore.fromJSON(settingsUtilsData.topCoursesByCourseCategory),
        topCoursesByCourseType: CourseTypeStore.fromJSON(settingsUtilsData.topCoursesByCourseType),
        topCoursesByListingPage: ListingPageUtils.fromJSON(settingsUtilsData.topCoursesByListingPage),
        homePageSettingsData: HomePageStore.fromJSON(settingsUtilsData.homePageSettingsData),
        mostReadNews: settingsUtilsData?.mostReadNews,
        newsListingPromoBox: settingsUtilsData?.newsListingPromoBox
      };

      HomePageStore.getInstance().loadPreloadedHomePage(SettingsUtils.settingsUtilsData.homePageSettingsData);
      SettingsUtils.loaded = true;


      if (settingsUtilsSourceData.topCoursesByCourseCategory || settingsUtilsSourceData.topCoursesByCourseType || settingsUtilsSourceData.topCoursesByListingPage) {
        CourseCategoryStore.getInstance().loadPreloadedCourseCategories();
        CourseTypeStore.getInstance().loadPreloadedCourseTypes(
          SettingsUtils.settingsUtilsData.topCoursesByCourseType
        );
        ListingPageUtils.getInstance().loadPreloadedListingPageBestSellerCourses(
          SettingsUtils.settingsUtilsData.topCoursesByListingPage
        );
        SettingsUtils.loadedCourses = true;
      }
    }


    return SettingsUtils.settingsUtilsData;

  }

  private static getSettingsFields(items: ISettings[]): ISettings | null {
    if (items && items.length > 0) {
      return items[items.length - 1].fields || null;
    }
    return null;
  }

  public static async renderSettings(contentfulClient: ContentfulClientApi, _fetch: {
    (input: RequestInfo | URL, init?: RequestInit): Promise<Response>;
    (input: string | URL | globalThis.Request, init?: RequestInit): Promise<Response>;
  }): Promise<SettingsUtilsData> {

    // console.log("Rendering settings...");
    await DbUtils.loadCourses(fetch);
    // console.log("DB Loaded with ",Object.keys(DbUtils.getDbSynch()).length);
    return await contentfulClient
      .getEntries<ISettings>({
        content_type: 'settings',
      })
      .then(res => {
        // console.log("Returning Contentful items");
        return res.items;
      })
      .then(async (remoteSettingsObj) => {
        // console.log("Setting from contentful: ",remoteSettingsObj);

        SettingsUtils.initSettingsUtilsData();

        const settingsFields = SettingsUtils.getSettingsFields(remoteSettingsObj);

        if (settingsFields) {
          // trendCourses
          if (settingsFields.refsHPSeguiIlTrend) {
            const trendCodes = (settingsFields.refsHPSeguiIlTrend).split(',');
            for (let i = 0; i < trendCodes.length; i++) {
              const trendCode = trendCodes[i];
              // console.log("Getting refsHPSeguiIlTrend course by producxt code");
              const courseFromDb = DbUtils.getCourseByProductCode(trendCode);
              const course = SettingsUtils.cleanCourse(SettingsUtils.getCourseLightModelByProductCode(courseFromDb, trendCode));
              if (course) {
                // console.log("Course found");
                SettingsUtils.settingsUtilsData.homePageSettingsData.trendCourses.push(course);
              }
              else {
                // console.log("Course not found");
              }
            }
          }

          // popularCourses
          if (settingsFields.refPopularInSearch) {
            const popularCodes = (settingsFields.refPopularInSearch).split(',');
            for (let i = 0; i < popularCodes.length; i++) {
              const popularCode = popularCodes[i];
              const courseFromDb = DbUtils.getCourseByProductCode(popularCode);
              const course = SettingsUtils.cleanCourse(SettingsUtils.getCourseLightModelByProductCode(courseFromDb, popularCode));
              if (course) {
                SettingsUtils.settingsUtilsData.homePageSettingsData.popularCourses.push(course);
              }
            }
          }

          // Best Seller
          for (let fieldName of Object.keys(settingsFields)) {
            // Best Seller by category
            if (fieldName.startsWith('temBestSeller')) {
              const categoryKey = CourseCategoryStore.getCourseCategoryByFieldName(fieldName);
              if (categoryKey === -1) {
                // console.log("Invalid category");
                continue;
              }

              if (settingsFields[fieldName]) {
                const tempCodes = settingsFields[fieldName] as string[];
                tempCodes.forEach(code => {
                  const courseFromDb = DbUtils.getCourseByProductCode(code);
                  const course = SettingsUtils.cleanCourse(SettingsUtils.getCourseLightModelByProductCode(courseFromDb, code));
                  if (course) {
                    if (SettingsUtils.settingsUtilsData && SettingsUtils.settingsUtilsData.bestSellerCoursesProductCodes && !SettingsUtils.settingsUtilsData.bestSellerCoursesProductCodes.includes(code)) {
                      SettingsUtils.settingsUtilsData.bestSellerCoursesProductCodes.push(code);
                    }
                    SettingsUtils.settingsUtilsData.topCoursesByCourseCategory.bestSellerCourses.topics[categoryKey].courses.push(course);
                  }
                });
              }
            }

            // Best Seller by course type
            if (fieldName.startsWith('bestSeller')) {
              const courseTypeKey = CourseTypeStore.getCourseTypeByFieldName(fieldName);

              if (courseTypeKey === -1) {
                continue;
              }

              if (settingsFields[fieldName]) {
                const tempCodes = settingsFields[fieldName] as string[];
                tempCodes.forEach(code => {
                  const courseFromDb = DbUtils.getCourseByProductCode(code);
                  const course = SettingsUtils.cleanCourse(SettingsUtils.getCourseLightModelByProductCode(courseFromDb, code));

                  if (course) {
                    if (SettingsUtils.settingsUtilsData && SettingsUtils.settingsUtilsData.bestSellerCoursesProductCodes && !SettingsUtils.settingsUtilsData.bestSellerCoursesProductCodes.includes(code)) {
                      SettingsUtils.settingsUtilsData.bestSellerCoursesProductCodes.push(code);
                    }
                    SettingsUtils.settingsUtilsData.topCoursesByCourseType.bestSellerCourses.types[courseTypeKey].courses.push(course);
                  }
                });
              }
            }

            // Best Seller by listing page
            if (fieldName.startsWith('listingBestSeller')) {
              const listingPageKey = ListingPageUtils.getListingPageByFieldName(fieldName);

              if (listingPageKey === -1) {
                continue;
              }

              if (settingsFields[fieldName]) {
                const tempCodes = settingsFields[fieldName] as string[];
                tempCodes.forEach(code => {
                  const courseFromDb = DbUtils.getCourseByProductCode(code);
                  const course = SettingsUtils.cleanCourse(SettingsUtils.getCourseLightModelByProductCode(courseFromDb, code));

                  if (course) {
                    if (SettingsUtils.settingsUtilsData && SettingsUtils.settingsUtilsData.bestSellerCoursesProductCodes && !SettingsUtils.settingsUtilsData.bestSellerCoursesProductCodes.includes(code)) {
                      SettingsUtils.settingsUtilsData.bestSellerCoursesProductCodes.push(code);
                    }
                    SettingsUtils.settingsUtilsData.topCoursesByListingPage.bestSellerCourses.types[listingPageKey].courses.push(course);
                  }
                });
              }
            }


          }

          if (settingsFields.hpReviews && SettingsUtils.settingsUtilsData && SettingsUtils.settingsUtilsData.homePageSettingsData) {
            // const remoteHpReviews = settingsFields.hpReviews;
            const hpReviewsList: ReviewModel[] = [];

            // for (let review of remoteHpReviews) {
            //   let reviews = null
            //   if (review.fields.courseSlug && review.fields.courseSlug !== "") {
            //     reviews = await _fetch(`/api/v1/reviews/courseslug/${review.fields.courseSlug}.json`).then(res => res.ok ? res.json() : undefined);
            //     if ((!reviews || reviews.length <= 0) && review.fields.courseType) {
            //       reviews = await _fetch(`/api/v1/reviews/coursetypes/${stringToSlug(review.fields.courseType)}.json`).then(res => res.ok ? res.json() : undefined);
            //     }
            //   } else {
            //     reviews = await _fetch(`/api/v1/reviews/coursetypes/${stringToSlug(review.fields.courseType)}.json`).then(res => res.ok ? res.json() : undefined);
            //   }
            //   if (!reviews || reviews.length <= 0) {
            //     continue;
            //   }
            //   const tmpReview = reviews.find(element => element.id === review.sys.id);
            //   if (tmpReview) {
            //     hpReviewsList.push(tmpReview);
            //   }
            // }

            SettingsUtils.settingsUtilsData.homePageSettingsData.aboutUsReviews = hpReviewsList;
          }

          if (settingsFields.hpPromoBox.fields && SettingsUtils.settingsUtilsData && SettingsUtils.settingsUtilsData.homePageSettingsData) {
            const hpPromoBox: IPromoBoxes | null = settingsFields.hpPromoBox.fields;

            SettingsUtils.settingsUtilsData.homePageSettingsData.promoBox = hpPromoBox;
          }

          // Homepage news
          if (settingsFields.refHomepageNews && SettingsUtils.settingsUtilsData && SettingsUtils.settingsUtilsData.homePageSettingsData) {
            SettingsUtils.settingsUtilsData.homePageSettingsData.news = settingsFields.refHomepageNews;
          }

          // Most read news
          if (settingsFields.refMostReadNews && SettingsUtils.settingsUtilsData) {
            SettingsUtils.settingsUtilsData.mostReadNews = settingsFields.refMostReadNews;
          }

          // News promo box
          if (settingsFields.newsListingPromoBox && SettingsUtils.settingsUtilsData) {
            SettingsUtils.settingsUtilsData.newsListingPromoBox = settingsFields.newsListingPromoBox;
          }
        }


        return SettingsUtils.settingsUtilsData;

      }).catch(e => {
        // console.log("ERROR WHILE LOADING SETTINGS: ", e);
        return SettingsUtils.settingsUtilsData;
      });
  }


  public static getCourseLightModelByProductCode(course: CourseModel, productCode: string): CourseLightModel | null {
    if (course) {

      const session = Object.values(course.sessions).find(session => session.productCode === productCode);
      const courseLight = new CourseLightModel();
      if (session?.idSession) {
        courseLight.idMainSession = session?.idSession;
      }
      courseLight.addSession(new SessionLightModel(session, true));
      return courseLight;
    }
    return null;
  }

  public static cleanCourse(
    course: CourseModel | CourseLightModel
  ): CourseLightModel | null {
    if (!course) {
      return null;
    }
    const localCourse: CourseLightModel | null =
      course instanceof CourseModel
        ? CourseLightModel.fromCourseModel(course)
        : CourseLightModel.fromJSON(course);

    // const tempSession = localCourse.sessionLight;

    // for (let session of Object.values(tempSession)) {
    //   if (session.idSession !== localCourse.idMainSession) {
    //     delete localCourse.sessions[session.idSession];
    //   }
    // }

    return localCourse;
  }
}





