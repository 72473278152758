import { CourseLightModel } from "@24hbs/utils";
import { CourseType, CourseTypeStore } from "$lib/utils/courses/type-store"
import { type IListingPageDetails, type IListingPageListCourses, type ITopCoursesByListingPage, ListingPage } from "./types";
import { listingPageData } from "./mocks/listing-page";
import { writable, type Writable } from "svelte/store";
import type { IFilters } from "$lib/utils/filters/types";
import { CourseCategory, CourseCategoryStore } from "$lib/utils/courses/category-store";

const listingPageSlugByCourseTypeMap = {
  "corsi-executive": ListingPage.CorsiExecutive,
  "corsi-online": ListingPage.CorsiOnline, 
  "executive-master": ListingPage.ExecutiveMaster,
  "master-full-time": ListingPage.MasterPostLaureaConStage,
  "masterclass": ListingPage.Masterclass,
  "professional-master": ListingPage.ProfessionalMaster,
  "master-part-time": ListingPage.MasterPartTime,
  "mba-emba": ListingPage.MBA,
  "master-universitari-con-cfu": ListingPage.ProfessionalMasterCFU,
  "master-euroconference": ListingPage.CorsiEuroconference,
}

const listingPageSlugByCourseCategoryMap = {
  "ambiente-energia-e-pa": ListingPage.AmbienteEnergiaPA,
  "ambiente-energia-e-p-a": ListingPage.AmbienteEnergiaPA,
  "ambiente-energia-pa": ListingPage.AmbienteEnergiaPA,
  "self-education-energia-pa": ListingPage.AmbienteEnergiaPA,

  "amministrazione-finanza-e-controllo": ListingPage.AmministrazioneFinanzaControllo,
  "self-education-afc": ListingPage.AmministrazioneFinanzaControllo,

  "arte-cinema-e-design": ListingPage.ArteCinemaDesign,
  "self-education-arte": ListingPage.ArteCinemaDesign,

  "coding-academy": ListingPage.CodingAcademy,

  "digital-academy": ListingPage.DigitalAcademy,

  "diritto-e-fisco": ListingPage.DirittoFisco,
  "self-education-diritto-fisco": ListingPage.DirittoFisco,

  "food-beverage-e-turismo": ListingPage.FoodBeverageTurismo,
  "self-education-food": ListingPage.FoodBeverageTurismo,

  "gestione-di-impresa-export-e-internazionalizzazione": ListingPage.GestioneImpresaExport,
  "gestione-dimpresa-export-e-internazionalizzazione": ListingPage.GestioneImpresaExport,

  "hr-lavoro-e-sviluppo-manageriale": ListingPage.HRLavoroSviluppoManageriale,
  "self-education-hr-lavoro": ListingPage.HRLavoroSviluppoManageriale,

  "lusso-e-moda": ListingPage.LussoModa,
  "self-education-lusso": ListingPage.LussoModa,

  "marketing-comunicazione-digital-e-social-media": ListingPage.MarketingComunicazioneDigitalSocial,

  "sanita-pharma-e-biomed": ListingPage.SanitaPharmaBiomed,
  "self-education-sanita": ListingPage.SanitaPharmaBiomed,

  "sport": ListingPage.Sport,
  "self-education-sport": ListingPage.Sport,

  "vendite-e-retail": ListingPage.VenditeRetail
}

/**
 * Map listing page page with ListingPage
 */
const listingPageSlugMap = {
  ...listingPageSlugByCourseTypeMap,
  ...listingPageSlugByCourseCategoryMap
};

const listingPageMap = [
  // by course type
  ListingPage.CorsiExecutive,
  ListingPage.CorsiOnline,
  ListingPage.ExecutiveMaster,
  ListingPage.MasterPostLaureaConStage,
  ListingPage.Masterclass,
  ListingPage.ProfessionalMaster,
  ListingPage.MasterPartTime,
  ListingPage.MBA,
  ListingPage.ProfessionalMasterCFU,
  ListingPage.CorsiEuroconference,
  // by thematic area
  ListingPage.MarketingComunicazioneDigitalSocial,
  ListingPage.GestioneImpresaExport,
  ListingPage.HRLavoroSviluppoManageriale,
  ListingPage.AmministrazioneFinanzaControllo,
  ListingPage.LussoModa,
  ListingPage.VenditeRetail,
  ListingPage.AmbienteEnergiaPA,
  ListingPage.SanitaPharmaBiomed,
  ListingPage.ArteCinemaDesign,
  ListingPage.DirittoFisco,
  ListingPage.FoodBeverageTurismo,
  ListingPage.Sport,
  ListingPage.CodingAcademy,
  ListingPage.DigitalAcademy
];

/**
 * Utility class for listing page
 */
export class ListingPageUtils {
  private static instance: ListingPageUtils | null;

  public listingPageBestSellerCourses: Writable<IListingPageListCourses> = <Writable<IListingPageListCourses>>writable(ListingPageUtils.initListingPageListCourses());
  
  private constructor() {}

  public static getInstance(): ListingPageUtils {
    if (!this.instance) {
      this.instance = new ListingPageUtils();
    }

    return this.instance;
  }

  /**
   * Load preloaded listing page best seller courses
   * @param topCoursesByListingPage top courses by listing page
   */
  public loadPreloadedListingPageBestSellerCourses (topCoursesByListingPage: ITopCoursesByListingPage) {
    this.listingPageBestSellerCourses.update( current => {
      current = topCoursesByListingPage.bestSellerCourses;
      return current;
    });
  }

  /**
   * Initialize top courses by listing page
   * @returns top courses by listing page
   */
  public static initTopCoursesByListingPage(): ITopCoursesByListingPage {
    return <ITopCoursesByListingPage>{
      bestSellerCourses: this.initListingPageListCourses(),
    };
  };

  /**
   * Initialize listing page list courses
   * @returns listing page list courses
   */
  public static initListingPageListCourses(): IListingPageListCourses {
    const listingPageListCourses: IListingPageListCourses = {
      types: [],
    };

    for (const listingPageKey of listingPageMap) {
      listingPageListCourses.types[listingPageKey] = {
        courses: [],
      };
    }

    return listingPageListCourses;
  };

  /**
   * fromJSON method for ITopCoursesByListingPage
   * @param topCoursesObj top courses by listing page object
   * @returns ITopCoursesByListingPage
   */
  public static fromJSON (topCoursesObj: ITopCoursesByListingPage): ITopCoursesByListingPage {
    const topCoursesByListingPage: ITopCoursesByListingPage = {
      bestSellerCourses: this.initListingPageListCourses(),
    };

    for (let i = 0; i < topCoursesObj.bestSellerCourses.types.length; i++) {
      if (topCoursesObj.bestSellerCourses.types[i]) {
        for (let k = 0; k < topCoursesObj.bestSellerCourses.types[i].courses.length; k++) {
          const course = CourseLightModel.fromJSON(topCoursesObj.bestSellerCourses.types[i].courses[k]);
          topCoursesByListingPage.bestSellerCourses.types[i].courses.push(course);
        }
      }
    }

    return topCoursesByListingPage;
  }

  /**
   * Get listing page full name by listing page slug
   * @param listingPageSlug listing page slug
   * @returns listing page full name
   */
  public static getListingPageFullNameByListingPageSlug (listingPageSlug: string): string {
    switch (listingPageSlugMap[listingPageSlug]) {
      case ListingPage.CorsiExecutive:
        return "Corsi Executive";

      case ListingPage.CorsiOnline:
        return "Corsi Online";

      case ListingPage.ExecutiveMaster:
        return "Executive Master";

      case ListingPage.MasterPostLaureaConStage:
        return "Master Post Laurea con Stage garantito";

      case ListingPage.Masterclass:
        return "Masterclass";

      case ListingPage.ProfessionalMaster:
        return "Professional Master";

      case ListingPage.MasterPartTime:
        return "Master Part Time";

      case ListingPage.MBA:
        return "MBA e EMBA";
      
      case ListingPage. ProfessionalMasterCFU:
        return "Professional Master con CFU";
      
      case ListingPage.CorsiEuroconference:
        return "Corsi Euroconference";

      case ListingPage.MarketingComunicazioneDigitalSocial:
        return "Marketing, Comunicazione, Digital e Social Media";

      case ListingPage.GestioneImpresaExport:
        return "Gestione di Impresa, Export e Internazionalizzazione";

      case ListingPage.HRLavoroSviluppoManageriale:
        return "HR, Lavoro e Sviluppo Manageriale";

      case ListingPage.AmministrazioneFinanzaControllo:
        return "Amministrazione, Finanza e Controllo";

      case ListingPage.LussoModa:
        return "Lusso e Moda";

      case ListingPage.VenditeRetail:
        return "Vendite e Retail";

      case ListingPage.AmbienteEnergiaPA:
        return "Ambiente, Energia e P.A.";

      case ListingPage.SanitaPharmaBiomed:
        return "Sanità, Pharma e Biomed";

      case ListingPage.ArteCinemaDesign:
        return "Arte, Cinema e Design";

      case ListingPage.DirittoFisco:
        return "Diritto e Fisco";

      case ListingPage.FoodBeverageTurismo:
        return "Food, Beverage e Turismo";

      case ListingPage.Sport:
        return "Sport";

      case ListingPage.CodingAcademy:
        return "Coding Academy";

      case ListingPage.DigitalAcademy:
        return "Digital Academy";
    }
  }

  /**
   * Get course types by listing page slug
   * @param listingPageSlug listing page slug
   * @returns course types
   */
  public static getCourseTypesByListingPageSlug(listingPageSlug: string): { courseTypes: CourseType[], filters: IFilters } {
    return listingPageData ? listingPageData[listingPageSlug].in : { courseTypes: [], filters: {} };
  }
  
  /**
   * Get course categories by listing page slug
   * @param listingPageSlug listing page slug
   * @returns course category
   */
  public static getCourseCategoryByListingPageSlug(listingPageSlug: string): { courseCategory: CourseCategory, filters: IFilters } {
    return listingPageData ? listingPageData[listingPageSlug].in : { courseCategory: [], filters: {} };
  }

  /**
   * Get string array of course types slug by listing page
   * @param listingPage listing page
   * @returns string[] course types slug
   */
  public static getCourseTypesSlugByListingPageSlug(listingPageSlug: string): { courseTypeSlug: string[], filters: IFilters } {
    const { courseTypes, filters} = this.getCourseTypesByListingPageSlug(listingPageSlug);

    return {
      courseTypeSlug: courseTypes.map(courseType => CourseTypeStore.getCourseTypeSlugByCourseType(CourseType[courseType as keyof typeof CourseType])),
      filters: filters,
    };
  }

  /**
   * Get string of course category slug by listing page
   * @param listingPage listing page
   * @returns string course categories slug
   */
  public static getCourseCategorySlugByListingPageSlug(listingPageSlug: string): { courseCategorySlug: string, filters: IFilters } {
    const { courseCategory, filters} = this.getCourseCategoryByListingPageSlug(listingPageSlug);

    console.log('courseCategory', courseCategory);

    return {
      courseCategorySlug: CourseCategoryStore.getCourseCategorySlugByCategory(CourseCategory[courseCategory as keyof typeof CourseCategory]),
      filters: filters,
    };
  }

  /**
   * Get listing page details by listing page slug
   * @param listingPageSlug listing page slug
   * @returns listing page details
   */
  public static getListingPageDetailsByListingPageSlug(listingPageSlug: string): IListingPageDetails {
    // by course type
    switch (listingPageSlugMap[listingPageSlug]) {
      case ListingPage.CorsiExecutive:
        return {
          gtm: {
            pageCategory: "Corsi - Executive",
          },
          title: "Corsi Executive",
          description: "Individua il tema più in linea con le tue necessità di aggiornamento. Perfeziona, integra e sviluppa le tue competenze per svolgere al meglio il tuo ruolo.",
        }

      case ListingPage.CorsiOnline:
        return {
          gtm: {
            pageCategory: "Corsi Online",
          },
          title: "Corsi Online",
          description: "Corsi Online description", // TODO: TO DEFINE
        }

      case ListingPage.ExecutiveMaster:
        return {
          gtm: {
            pageCategory: "Executive Master",
          },
          title: "Executive Master",
          description: "Per sviluppare e consolidare skills tecniche e competenze manageriali.",
        }

      case ListingPage.MasterPostLaureaConStage:
        return {
          gtm: {
            pageCategory: "Master Post Laurea con Stage garantito",
          },
          title: "Master Post Laurea con Stage garantito",
          description: "24ORE Business School propone una vasta scelta di Master Full Time con stage per giovani neolaureati che vogliono approfondire le loro conoscenze e consolidare le proprie basi per entrare nel mondo del lavoro. I Master prevedono la frequenza in aula e lezioni in live streaming di sei mesi e uno stage formativo equivalente presso aziende partner nazionali e multinazionali selezionate.",
        }

      case ListingPage.Masterclass:
        return {
          gtm: {
            pageCategory: "Masterclass",
          },
          title: "Masterclass",
          description: "Con le Masterclass, 24ORE Business School vuole creare un’occasione unica per aziende, liberi professionisti o per chi vuole avvicinarsi alle diverse tematiche proposte, di incontrare i migliori esperti in materia attraverso sessioni formative brevi, smart, flessibili e orientate al business. Inoltre, saranno integrate da presentazioni di casi di successo e best practices da cui trarre spunto.",
        }

      case ListingPage.ProfessionalMaster:
        return {
          gtm: {
            pageCategory: "Professional Master",
          },
          title: "Professional Master",
          description: "Un’occasione unica di crescita professionale. Scegli uno dei percorsi più affini alla tua carriera e arricchisci la tua formazione grazie a un network di professionisti.",
        }

      case ListingPage.MasterPartTime:
        return {
          gtm: {
            pageCategory: "Master Part Time",
          },
          title: "Master Part Time",
          description: "Master Part Time description", // TODO: TO DEFINE
        }

      case ListingPage.MBA:
        return {
          gtm: {
            pageCategory: "MBA e EMBA",
          },
          title: "MBA e EMBA",
          description: "MBA description", // TODO: TO DEFINE
        }
      
      case ListingPage. ProfessionalMasterCFU:
        return {
          gtm: {
            pageCategory: "Professional Master con CFU",
          },
          title: "Professional Master con CFU",
          description: "Professional Master con CFU description", // TODO: TO DEFINE
        }
      
      case ListingPage.CorsiEuroconference:
        return {
          gtm: {
            pageCategory: "Corsi Euroconference",
          },
          title: "Corsi Euroconference",
          description: "Il catalogo prodotti Euroconference ti offre percorsi formativi di alta qualità suddivisi in tre aree tematiche: Legale, Fiscale e Lavoro. La nostra proposta ti permette di accelerare la tua crescita professionale, acquisire competenze specializzate e rimanere sempre aggiornato sulle ultime novità, ottenendo anche crediti formativi",
        }

      // by course category
      case ListingPage.AmbienteEnergiaPA:
        return {
          gtm: {
            pageCategory: "Tematiche - Ambiente, Energia e P.A.",
          },
          title: "Ambiente, Energia e P.A.",
          description: "I Master e Corsi in Pubblica Amministrazione, Appalti Pubblici e Sostenibilità e Gestione Ambientale di 24ORE Business School si svolgono a Milano, Roma o interamente Online. Master Full Time e Part Time certificati per sviluppare competenze nella digitalizzazione della Pubblica Amministrazione, nella Gestione di Appalti, Forniture e Lavori Pubblici o nel settore della Green Economy."
        }

      case ListingPage.AmministrazioneFinanzaControllo:
        return {
          gtm: {
            pageCategory: "Tematiche - Amministrazione, Finanza e Controllo",
          },
          title: "Amministrazione, Finanza e Controllo",
          description: "24ORE Business School offre una vasta scelta formativa di Master in Amministrazione, Finanza e Controllo di Gestione, Banking e Fintech o Credit Management. Formazione certificata in modalità Executive, Full Time con stage, Part Time ed Online per acquisire competenze tecniche nella gestione del bilancio, nella scelta degli strumenti di private e corporate banking o nel risk management."
        }

      case ListingPage.ArteCinemaDesign:
        return {
          gtm: {
            pageCategory: "Tematiche - Arte, Cinema e Design",
          },
          title: "Arte, Cinema e Design",
          description: "I Master in Arte, Cinema e Design di 24ORE Business School si svolgono a Roma, Milano o in modalità Live Streaming. I Master Full Time con stage o Part Time sono rivolti a giovani laureati o neolaureandi che vogliono fare carriera nel settore Audiovisivo e del Media Entertainment. Un network di eccellenza, project work con rinomate case di produzione cinematografica e company tour completano l'offerta formativa."
        }

      case ListingPage.CodingAcademy:
        return {
          gtm: {
            pageCategory: "Tematiche - Coding Academy",
          },
          title: "Coding Academy",
          description: "24ORE Business School propone una vasta gamma di Corsi On Demand, pratici e veloci di breve durata per studiare quando, dove e ovunque ci si trovi. I Corsi On Demand sono rivolti a Manager, Professionisti e Studenti che vogliono approfondire aree di business specifiche. Prevedono video lezioni, podcast, test di valutazione finale e il rilascio di un attestato."
        }

      case ListingPage.DigitalAcademy:
        return {
          gtm: {
            pageCategory: "Tematiche - Digital Academy",
          },
          title: "Digital Academy",
          description: "24ORE Business School propone una vasta gamma di Corsi On Demand, pratici e veloci di breve durata per studiare quando, dove e ovunque ci si trovi. I Corsi On Demand sono rivolti a Manager, Professionisti e Studenti che vogliono approfondire aree di business specifiche. Prevedono video lezioni, podcast, test di valutazione finale e il rilascio di un attestato.",
        }

      case ListingPage.DirittoFisco:
        return {
          gtm: {
            pageCategory: "Tematiche - Diritto e Fisco",
          },
          title: "Diritto e Fisco",
          description: "Una vasta gamma di Master e Corsi di formazione a Milano, Roma ed online che 24ORE Business School propone a neolaureati e professionisti. Master in Diritto Tributario, Master in Diritto Tributario e d’Impresa, Diritto Bancario ma anche Contabilità e Bilancio e Data Protection. A scelta tra Master Executive, Full Time con stage, Part Time ed Online."
        }

      case ListingPage.FoodBeverageTurismo:
        return {
          gtm: {
            pageCategory: "Tematiche - Food, Beverage e Turismo",
          },
          title: "Food, Beverage e Turismo",
          description: "I nostri Master in Turismo, Food and Beverage, Hotel Management permettono di acquisire competenze e tecniche innovative per diventare esperto in Food Marketing, Marketing Turistico & Hotellerie, aree di eccellenza del Made in Italy. 24ORE Business School offre formazione in aula o in Live Streaming a Milano e Catania con Master Full Time con stage o Part Time."
        }

      case ListingPage.GestioneImpresaExport:
        return {
          gtm: {
            pageCategory: "Tematiche - Gestione di Impresa, Export e Internazionalizzazione",
          },
          title: "Gestione di Impresa, Export e Internazionalizzazione",
          description: "Una vasta gamma di Master e Corsi di formazione 24ORE Business School in Gestione d'Impresa, Export o Project Management a Milano, Roma, Catania o interamente Online. Master Full Time con stage, Part Time ed Executive per formare Imprenditori, Export Manager, Manager d'azienda con competenze tecniche certificate di alto livello."
        }

      case ListingPage.HRLavoroSviluppoManageriale:
        return {
          gtm: {
            pageCategory: "Tematiche - HR, Lavoro e Sviluppo Manageriale",
          },
          title: "HR, Lavoro e Sviluppo Manageriale",
          description: "Master e Corsi in Human Resources, HR Management, Gestione delle Risorse Umane e Diritto del lavoro, sono alcune delle migliori offerte di formazione della 24ORE Business School. Master Executive, Full Time con Stage, Part Time ed Online per sviluppare strumenti e competenze nel mercato del lavoro e recruiting per diventare HR Manager o HR Specialist. Formazione in Streaming e in Aula a Milano e Roma."
        }

      case ListingPage.LussoModa:
        return {
          gtm: {
            pageCategory: "Tematiche - Lusso e Moda",
          },
          title: "Lusso e Moda",
          description: "La più ampia offerta formativa di Master e Corsi di Fashion Marketing e Luxury Management. Master di Moda Full Time, Part Time e Online a Milano e Roma. La frequenza dei nostri master in Fashion e Luxury può avvenire in aula o in streaming. Fashion Buying, Design e Comunicazione di Moda, Marketing e Brand Strategy per diventare un vero professionista del settore Luxury con 24ORE Business School."
        }

      case ListingPage.MarketingComunicazioneDigitalSocial:
        return {
          gtm: {
            pageCategory: "Tematiche - Marketing, Comunicazione, Digital e Social Media",
          },
          title: "Marketing, Comunicazione, Digital e Social Media",
          description: "Master in Digital Marketing, Digital Communication o Influencer Marketing sono solo alcuni dei Master Part Time o Full Time con stage proposti da 24ORE Business School per diventare esperti del settore. I Master si svolgono a Milano, Roma, Catania, in Live Streaming o interamente Online e consentono di acquisire competenze specifiche per svolgere le professioni più richieste dal mercato digitale come Social Media Manager, Product o Web Marketing Manager, Media Planner."
        }
      
      case ListingPage.SanitaPharmaBiomed:
        return {
          gtm: {
            pageCategory: "Tematiche - Sanità, Pharma e Biomed",
          },
          title: "Sanità, Pharma e Biomed",
          description: "Un'ampia scelta formativa con i nostri Master in Management Sanitario, Marketing Farmaceutico o Economia e Management dei servizi sanitari. Formazione certificata in modalità Executive, Full Time con stage, Part Time ed Online per acquisire competenze specifiche e diventare esperto del settore sanitario e farmaceutico con 24ORE Business School."
        }

      case ListingPage.Sport:
        return {
          gtm: {
            pageCategory: "Tematiche - Sport",
          },
          title: "Sport",
          description: "Con i Master e Corsi in Sport Management di 24ORE Business School è possibile trasformare una passione in una professione. Master Full Time con stage, Part Time o interamente Online a Milano e Roma per avere una visione a 360 gradi dello Sport Business e imparare la Comunicazione, il Marketing dello Sport, la Gestione di Eventi Sportivi o i Diritti Media dello Sport. I master prevedono il rilascio di un certificato."
        }

      case ListingPage.VenditeRetail:
        return {
          gtm: {
            pageCategory: "Tematiche - Vendite e Retail",
          },
          title: "Vendite e Retail",
          description: "Un'ampia scelta di Master in Retail, Marketing e Sales Management per acquisire tutte le competenze e tecniche di una strategia omnichannel, il Marketing Drive to Store e Supply Chain. Master Full Time con stage, Part Time o interamente Online a Milano e Roma."
        }

      default:
        return {
          gtm: {
            pageCategory: "",
          },
          title: "",
          description: "",
        };
    }
  }

  /**
   * Get listing page by listing page slug
   * @param listingPageSlug listing page slug
   * @returns listing page
   */
  public static getListingPageByListingPageSlug(listingPageSlug: string): ListingPage {
    return listingPageSlugMap[listingPageSlug];
  }

  /**
   * Get listing page slug by listing page
   * @param listingPage listing page
   * @returns listing page slug
   */
  public static getListingPageSlugByListingPage(listingPage: ListingPage): string | null {
    // search in listingPageSlugMap by value
    const listingPageSlug = Object.keys(listingPageSlugMap).find(key => listingPageSlugMap[key] === listingPage);
    return listingPageSlug ? listingPageSlug : null;
  }

  /**
   * Get all listing page types slug
   * @returns string[] listing page slug
   */
  public static getAllListingPageSlug(): string[] {
    return Object.keys(listingPageSlugMap);
  }

  /**
   * Get all listing page types slug by course type
   * @returns ListingPage[] listing page
   */
  public static getAllListingPageByCourseType(): string[] {
    return Object.keys(listingPageSlugByCourseTypeMap);
  }

  /**
   * Get all listing page types slug by course category
   * @returns ListingPage[] listing page
   */
  public static getAllListingPageByCourseCategory(): string[] {
    return Object.keys(listingPageSlugByCourseCategoryMap);
  }

  /**
   * Get listing page css class by listing page slug
   * @param listingPageSlug listing page slug
   * @return string
   */
  public static getListingPageCssClassByListingPageSlug (listingPageSlug: string): string {
    switch (listingPageSlugMap[listingPageSlug]) {
      case ListingPage.CorsiExecutive:
        return "corsiExecutiveListingColor";

      case ListingPage.CorsiOnline:
        return "corsiOnlineListingColor";

      case ListingPage.ExecutiveMaster:
        return "executiveMasterListingColor";

      case ListingPage.MasterPostLaureaConStage:
        return "masterPostLaureaListingColor";

      case ListingPage.Masterclass:
        return "masterclassListingColor";

      case ListingPage.ProfessionalMaster:
        return "professionalMasterListingColor";

      case ListingPage.MasterPartTime:
        return "masterPartTimeListingColor";

      case ListingPage.MBA:
        return "mbaListingColor";

      case ListingPage. ProfessionalMasterCFU:
        return "professionalMasterCFUListingColor";

      case ListingPage.CorsiEuroconference:
        return "corsiEuroconferenceListingColor";

      default:
        return "defaultListingColor";
    }
  }

  public static getListingPageByFieldName (remoteSettingsFieldName: string): number {
    switch (remoteSettingsFieldName) {
      case "listingBestSellerCorsiExecutive":
        return ListingPage.CorsiExecutive;

      case "listingBestSellerCorsiOnline":
        return ListingPage.CorsiOnline;

      case "listingBestSellerExecutiveMaster":
        return ListingPage.ExecutiveMaster;

      case "listingBestSellerMasterPostLaureaConStage":
        return ListingPage.MasterPostLaureaConStage;

      case "listingBestSellerMasterclass":
        return ListingPage.Masterclass;

      case "listingBestSellerProfessionalMaster":
        return ListingPage.ProfessionalMaster;

      case "listingBestSellerMasterPartTime":
        return ListingPage.MasterPartTime;

      case "listingBestSellerMBAEMBA":
        return ListingPage.MBA;

      case "listingBestSellerMasterUniversitariConCfu":
        return ListingPage. ProfessionalMasterCFU;

      case "listingBestSellerMasterEuroconference":
        return ListingPage.CorsiEuroconference;

      default:
        return -1;
    }
  }

}